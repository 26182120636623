import React from "react";
import { useState, useEffect } from "react";
import { Route, Routes, Navigate, useSearchParams } from "react-router-dom";
import withRouter from "../Router/withRouter";
import classnames from "classnames";
import { Box, IconButton, Link } from "@mui/material";
import Icon from "@mdi/react";
import Toolbar from "@mui/material/Toolbar";
//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from "@mdi/js";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import HomePage from "../../pages/home/HomePage";

// import Typography from "../../pages/typography";
// import Notifications from "../../pages/notifications";
// import Maps from "../../pages/maps";
// import Tables from "../../pages/tables";
// import Icons from "../../pages/icons";
// import Charts from "../../pages/charts";

//contexts
import { useLayoutState } from "../../context/LayoutContext";
import { useLocation } from "react-router-dom";

function Layout(props) {
  const [searchParams] = useSearchParams();
  const [viewMode, setViewMode] = useState("full");
  useEffect(() => {
    //Set the view mode based on the url parameter
    const view = searchParams.get("view") || "full";
    if (view === "full") setViewMode("full");
    if (view === "main") setViewMode("main");
  }, [searchParams]);

  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        {viewMode === "full" && (
          <>
            <Header history={props.history} />
            <Sidebar />
          </>
        )}
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          {/* <Toolbar/> used here to padding the top banner */}
          {viewMode === "full" && <Toolbar></Toolbar>}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Routes>
          <Box
            mt={5}
            width={"100%"}
            // display={"flex"}
            display={"none"}
            alignItems={"center"}
            justifyContent="space-between"
          >
            <div>
              <Link
                color={"primary"}
                href={"https://flatlogic.com/"}
                target={"_blank"}
                className={classes.link}
              >
                Flatlogic
              </Link>
              <Link
                color={"primary"}
                href={"https://flatlogic.com/about"}
                target={"_blank"}
                className={classes.link}
              >
                About Us
              </Link>
              <Link
                color={"primary"}
                href={"https://flatlogic.com/blog"}
                target={"_blank"}
                className={classes.link}
              >
                Blog
              </Link>
            </div>
            <div>
              <Link
                href={"https://www.facebook.com/flatlogic"}
                target={"_blank"}
              >
                <IconButton aria-label="facebook">
                  <Icon path={FacebookIcon} size={1} color="#6E6E6E99" />
                </IconButton>
              </Link>
              <Link href={"https://twitter.com/flatlogic"} target={"_blank"}>
                <IconButton aria-label="twitter">
                  <Icon path={TwitterIcon} size={1} color="#6E6E6E99" />
                </IconButton>
              </Link>
              <Link href={"https://github.com/flatlogic"} target={"_blank"}>
                <IconButton aria-label="github" style={{ marginRight: -12 }}>
                  <Icon path={GithubIcon} size={1} color="#6E6E6E99" />
                </IconButton>
              </Link>
            </div>
          </Box>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
