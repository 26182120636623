import { time } from "highcharts";

export const SPEEDS = [
  {
    time: "12:00 AM",
    speed: 61.2,
  },
  {
    time: "12:15 AM",
    speed: 60.5,
  },
  {
    time: "12:30 AM",
    speed: 60.3,
  },
  {
    time: "12:45 AM",
    speed: 60.4,
  },
  {
    time: "1:00 AM",
    speed: 61.1,
  },
  {
    time: "1:15 AM",
    speed: 61.6,
  },
  {
    time: "1:30 AM",
    speed: 62,
  },
  {
    time: "1:45 AM",
    speed: 62.2,
  },
  {
    time: "2:00 AM",
    speed: 62.4,
  },
  {
    time: "2:15 AM",
    speed: 62.6,
  },
  {
    time: "2:30 AM",
    speed: 62.6,
  },
  {
    time: "2:45 AM",
    speed: 62.3,
  },
  {
    time: "3:00 AM",
    speed: 62.2,
  },
  {
    time: "3:15 AM",
    speed: 62.1,
  },
  {
    time: "3:30 AM",
    speed: 62.7,
  },
  {
    time: "3:45 AM",
    speed: 63.5,
  },
  {
    time: "4:00 AM",
    speed: 64.4,
  },
  {
    time: "4:15 AM",
    speed: 64.4,
  },
  {
    time: "4:30 AM",
    speed: 64.4,
  },
  {
    time: "4:45 AM",
    speed: 64.4,
  },
  {
    time: "5:00 AM",
    speed: 64.4,
  },
  {
    time: "5:15 AM",
    speed: 64.4,
  },
  {
    time: "5:30 AM",
    speed: 64.4,
  },
  {
    time: "5:45 AM",
    speed: 64.4,
  },
  {
    time: "6:00 AM",
    speed: 62.9,
  },
  {
    time: "6:15 AM",
    speed: 59.9,
  },
  {
    time: "6:30 AM",
    speed: 56.3,
  },
  {
    time: "6:45 AM",
    speed: 51.7,
  },
  {
    time: "7:00 AM",
    speed: 46.5,
  },
  {
    time: "7:15 AM",
    speed: 41.2,
  },
  {
    time: "7:30 AM",
    speed: 37.5,
  },
  {
    time: "7:45 AM",
    speed: 35.5,
  },
  {
    time: "8:00 AM",
    speed: 35.7,
  },
  {
    time: "8:15 AM",
    speed: 38.3,
  },
  {
    time: "8:30 AM",
    speed: 43,
  },
  {
    time: "8:45 AM",
    speed: 48.7,
  },
  {
    time: "9:00 AM",
    speed: 54.2,
  },
  {
    time: "9:15 AM",
    speed: 58.3,
  },
  {
    time: "9:30 AM",
    speed: 60.8,
  },
  {
    time: "9:45 AM",
    speed: 62,
  },
  {
    time: "10:00 AM",
    speed: 62.3,
  },
  {
    time: "10:15 AM",
    speed: 62.4,
  },
  {
    time: "10:30 AM",
    speed: 62.4,
  },
  {
    time: "10:45 AM",
    speed: 62.5,
  },
  {
    time: "11:00 AM",
    speed: 62.5,
  },
  {
    time: "11:15 AM",
    speed: 62.6,
  },
  {
    time: "11:30 AM",
    speed: 62.7,
  },
  {
    time: "11:45 AM",
    speed: 62.7,
  },
  {
    time: "12:00 PM",
    speed: 62.6,
  },
  {
    time: "12:15 PM",
    speed: 62.6,
  },
  {
    time: "12:30 PM",
    speed: 62.4,
  },
  {
    time: "12:45 PM",
    speed: 62.4,
  },
  {
    time: "1:00 PM",
    speed: 62.3,
  },
  {
    time: "1:15 PM",
    speed: 62.3,
  },
  {
    time: "1:30 PM",
    speed: 62.4,
  },
  {
    time: "1:45 PM",
    speed: 62.3,
  },
  {
    time: "2:00 PM",
    speed: 62.4,
  },
  {
    time: "2:15 PM",
    speed: 62.5,
  },
  {
    time: "2:30 PM",
    speed: 62.6,
  },
  {
    time: "2:45 PM",
    speed: 62.4,
  },
  {
    time: "3:00 PM",
    speed: 62.2,
  },
  {
    time: "3:15 PM",
    speed: 61.9,
  },
  {
    time: "3:30 PM",
    speed: 61.4,
  },
  {
    time: "3:45 PM",
    speed: 60.6,
  },
  {
    time: "4:00 PM",
    speed: 59.6,
  },
  {
    time: "4:15 PM",
    speed: 58.4,
  },
  {
    time: "4:30 PM",
    speed: 57.3,
  },
  {
    time: "4:45 PM",
    speed: 56.2,
  },
  {
    time: "5:00 PM",
    speed: 55.7,
  },
  {
    time: "5:15 PM",
    speed: 56,
  },
  {
    time: "5:30 PM",
    speed: 57.3,
  },
  {
    time: "5:45 PM",
    speed: 58.9,
  },
  {
    time: "6:00 PM",
    speed: 60.8,
  },
  {
    time: "6:15 PM",
    speed: 62.4,
  },
  {
    time: "6:30 PM",
    speed: 63.5,
  },
  {
    time: "6:45 PM",
    speed: 63.9,
  },
  {
    time: "7:00 PM",
    speed: 64.2,
  },
  {
    time: "7:15 PM",
    speed: 64.2,
  },
  {
    time: "7:30 PM",
    speed: 64.2,
  },
  {
    time: "7:45 PM",
    speed: 64.2,
  },
  {
    time: "8:00 PM",
    speed: 64,
  },
  {
    time: "8:15 PM",
    speed: 63.7,
  },
  {
    time: "8:30 PM",
    speed: 63.6,
  },
  {
    time: "8:45 PM",
    speed: 63.3,
  },
  {
    time: "9:00 PM",
    speed: 63.1,
  },
  {
    time: "9:15 PM",
    speed: 63.1,
  },
  {
    time: "9:30 PM",
    speed: 63.2,
  },
  {
    time: "9:45 PM",
    speed: 63.2,
  },
  {
    time: "10:00 PM",
    speed: 63.1,
  },
  {
    time: "10:15 PM",
    speed: 63.2,
  },
  {
    time: "10:30 PM",
    speed: 63.1,
  },
  {
    time: "10:45 PM",
    speed: 63.2,
  },
  {
    time: "11:00 PM",
    speed: 63.3,
  },
  {
    time: "11:15 PM",
    speed: 63.1,
  },
  {
    time: "11:30 PM",
    speed: 62.9,
  },
  {
    time: "11:45 PM",
    speed: 62.7,
  },
];

export const SPEEDS_OLD = [
  { time: "12:00 AM", speed: 69 },
  { time: "12:15 AM", speed: 68 },
  { time: "12:30 AM", speed: 68 },
  { time: "12:45 AM", speed: 68 },
  { time: "1:00 AM", speed: 64 },
  { time: "1:15 AM", speed: 66 },
  { time: "1:30 AM", speed: 66 },
  { time: "1:45 AM", speed: 70 },
  { time: "2:00 AM", speed: 68 },
  { time: "2:15 AM", speed: 69 },
  { time: "2:30 AM", speed: 67 },
  { time: "2:45 AM", speed: 66 },
  { time: "3:00 AM", speed: 67 },
  { time: "3:15 AM", speed: 67 },
  { time: "3:30 AM", speed: 69 },
  { time: "3:45 AM", speed: 70 },
  { time: "4:00 AM", speed: 69 },
  { time: "4:15 AM", speed: 69 },
  { time: "4:30 AM", speed: 69 },
  { time: "4:45 AM", speed: 70 },
  { time: "5:00 AM", speed: 71 },
  { time: "5:15 AM", speed: 70 },
  { time: "5:30 AM", speed: 70 },
  { time: "5:45 AM", speed: 71 },
  { time: "6:00 AM", speed: 70 },
  { time: "6:15 AM", speed: 68 },
  { time: "6:30 AM", speed: 69 },
  { time: "6:45 AM", speed: 72 },
  { time: "7:00 AM", speed: 68 },
  { time: "7:15 AM", speed: 68 },
  { time: "7:30 AM", speed: 68 },
  { time: "7:45 AM", speed: 68 },
  { time: "8:00 AM", speed: 68 },
  { time: "8:15 AM", speed: 68 },
  { time: "8:30 AM", speed: 68 },
  { time: "8:45 AM", speed: 67 },
  { time: "9:00 AM", speed: 69 },
  { time: "9:15 AM", speed: 67 },
  { time: "9:30 AM", speed: 66 },
  { time: "9:45 AM", speed: 68 },
  { time: "10:00 AM", speed: 67 },
  { time: "10:15 AM", speed: 67 },
  { time: "10:30 AM", speed: 68 },
  { time: "10:45 AM", speed: 67 },
  { time: "11:00 AM", speed: 65 },
  { time: "11:15 AM", speed: 66 },
  { time: "11:30 AM", speed: 66 },
  { time: "11:45 AM", speed: 66 },
  { time: "12:00 PM", speed: 66 },
  { time: "12:15 PM", speed: 67 },
  { time: "12:30 PM", speed: 67 },
  { time: "12:45 PM", speed: 68 },
  { time: "1:00 PM", speed: 67 },
  { time: "1:15 PM", speed: 67 },
  { time: "1:30 PM", speed: 66 },
  { time: "1:45 PM", speed: 66 },
  { time: "2:00 PM", speed: 67 },
  { time: "2:15 PM", speed: 65 },
  { time: "2:30 PM", speed: 65 },
  { time: "2:45 PM", speed: 64 },
  { time: "3:00 PM", speed: 63 },
  { time: "3:15 PM", speed: 63 },
  { time: "3:30 PM", speed: 62 },
  { time: "3:45 PM", speed: 55 },
  { time: "4:00 PM", speed: 45 },
  { time: "4:15 PM", speed: 33 },
  { time: "4:30 PM", speed: 35 },
  { time: "4:45 PM", speed: 35 },
  { time: "5:00 PM", speed: 31 },
  { time: "5:15 PM", speed: 37 },
  { time: "5:30 PM", speed: 32 },
  { time: "5:45 PM", speed: 31 },
  { time: "6:00 PM", speed: 29 },
  { time: "6:15 PM", speed: 32 },
  { time: "6:30 PM", speed: 31 },
  { time: "6:45 PM", speed: 44 },
  { time: "7:00 PM", speed: 66 },
  { time: "7:15 PM", speed: 64 },
  { time: "7:30 PM", speed: 66 },
  { time: "7:45 PM", speed: 66 },
  { time: "8:00 PM", speed: 66 },
  { time: "8:15 PM", speed: 67 },
  { time: "8:30 PM", speed: 66 },
  { time: "8:45 PM", speed: 67 },
  { time: "9:00 PM", speed: 66 },
  { time: "9:15 PM", speed: 67 },
  { time: "9:30 PM", speed: 69 },
  { time: "9:45 PM", speed: 70 },
  { time: "10:00 PM", speed: 68 },
  { time: "10:15 PM", speed: 67 },
  { time: "10:30 PM", speed: 69 },
  { time: "10:45 PM", speed: 67 },
  { time: "11:00 PM", speed: 69 },
  { time: "11:15 PM", speed: 67 },
  { time: "11:30 PM", speed: 66 },
  { time: "11:45 PM", speed: 70 },
];

export const SPEEDS_METRIC = SPEEDS.map(({ time, speed }) => ({
  time,
  speed: speed / 0.621371,
}));
export const SPEEDS_REFERENCE = SPEEDS.map(({ time, speed }) => ({
  time,
  speed,
  ref: 60,
}));
export const INITIAL_INPUTS = {
  units: { speed: "mph", volume: "gallons", weight: "lbs" },

  submitOutputs: null,
  "value-of-time-truck": 54.94,
  "value-of-time-passenger": 18.12,
  "fuel-consumption-truck": 0.044557,
  "fuel-consumption-passenger": 0.051040048,
  useMetricSystem: false,
  test: 100,
  beforeScenarioInputs: {
    aadt: 86000,
    lanes: 4,
    facilityType: "Freeway",
    aadtPerLane: 21500,
    refSpeed: 60,
    length: 3.57,
    dailyTruckPercent: 5,
    volProfFunClass: "fway",
    dayType: "Weekday",
    peakingDirection: "AM Peak",
    congestionLevel: "Severe",
    climateCluster: 4,
    co2FunClass: "fway",
    laneAddition: 0,
    treatmentType: "No Treatment",
    treatmentTypes: [],
    speeds: SPEEDS,
  },
  afterScenarioInputs: {
    aadt: 86000,
    lanes: 4,
    facilityType: "Freeway",
    aadtPerLane: 20000,
    refSpeed: 60,
    length: 3.57,
    dailyTruckPercent: 5,
    volProfFunClass: "fway",
    dayType: "Weekday",
    peakingDirection: "AM Peak",
    congestionLevel: "Severe",
    climateCluster: 4,
    co2FunClass: "fway",
    laneAddition: 0.2,
    treatmentType: "Variable speed limit",
    treatmentTypes: [],
    speeds: new Array(96),
  },
  projectInfo: {
    facilityName: "NW Loop 410 (EB)",
    facilityLocation: "San Antonio, TX",
    description: "Install variable speed limit",
    baseAnalysisYear: 2021,
  },
};

export const EMPTY_INPUTS = {
  units: { speed: "mph", volume: "gallons", weight: "lbs" },

  submitOutputs: null,
  "value-of-time-truck": 54.94,
  "value-of-time-passenger": 18.12,
  "fuel-consumption-truck": 0.044557,
  "fuel-consumption-passenger": 0.051040048,
  useMetricSystem: false,
  test: 100,
  beforeScenarioInputs: {
    aadt: null,
    lanes: null,
    facilityType: null,
    aadtPerLane: null,
    refSpeed: null,
    length: null,
    dailyTruckPercent: 5,
    volProfFunClass: null,
    dayType: null,
    peakingDirection: null,
    congestionLevel: null,
    climateCluster: 4,
    co2FunClass: null,
    laneAddition: 0,
    treatmentType: null,
    treatmentTypes: [],
    speeds: SPEEDS.map(({ time, speed }) => {
      return { time, speed: null };
    }),
  },
  afterScenarioInputs: {
    aadt: null,
    lanes: null,
    facilityType: null,
    aadtPerLane: null,
    refSpeed: null,
    length: null,
    dailyTruckPercent: null,
    volProfFunClass: null,
    dayType: null,
    peakingDirection: null,
    congestionLevel: null,
    climateCluster: 4,
    co2FunClass: null,
    laneAddition: null,
    treatmentType: null,
    treatmentTypes: [],
    speeds: new Array(96),
  },
  projectInfo: {
    facilityName: null,
    facilityLocation: null,
    description: null,
    baseAnalysisYear: null,
  },
};

export const INITIAL_INPUTS_METRIC = {
  submitOutputs: null,
  "value-of-time-truck": 54.94,
  "value-of-time-passenger": 18.12,
  "fuel-consumption-truck": 0.044557 / 8.3454,
  "fuel-consumption-passenger": 0.051040048 / 8.3454,
  useMetricSystem: false,
  test: 100,
  beforeScenarioInputs: {
    aadt: 100000,
    lanes: 4,
    facilityType: "fway",
    aadtPerLane: 25000,
    refSpeed: 65 / 0.621371,
    length: 3.57 / 0.621371,
    dailyTruckPercent: 15,
    volProfFunClass: "fway",
    dayType: "wkd",
    peakingDirection: "pmpk",
    congestionLevel: "severe",
    climateCluster: 1,
    co2FunClass: "fway",
    laneAddition: 0,
    treatmentTypes: [],
    speeds: SPEEDS_METRIC,
  },
  afterScenarioInputs: {
    aadt: 100000,
    lanes: 5,
    facilityType: "fway",
    aadtPerLane: 20000,
    refSpeed: 65 / 0.621371,
    length: 3.57 / 0.621371,
    dailyTruckPercent: 15,
    volProfFunClass: "fway",
    dayType: "wkd",
    peakingDirection: "pmpk",
    congestionLevel: "severe",
    climateCluster: 1,
    co2FunClass: "fway",
    laneAddition: 0,
    treatmentTypes: [],
  },
};
