import React from "react";
import Stack from "@mui/material/Stack";
// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

export default function PageTitle(props) {
  var classes = useStyles();

  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} variant="h1" size="sm">
        {props.title}
      </Typography>
      {props.button && <Stack direction="row" spacing={1}>{props.button}</Stack>}
    </div>
  );
}
