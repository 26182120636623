import React, { useState } from "react";
import { Grid, Select, MenuItem, Input } from "@mui/material";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import { useTheme } from "@mui/styles";
import { BarChart, Bar } from "recharts";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Widget from "../../../../components/Widget";
import { Typography } from "../../../../components/Wrappers";

// constants
import { OUTPUT_ELEMENTS as columns } from "../../../../constants/UIConstants";

export default function SmallStat(props) {
  const { product, outputs = [{ before: {}, after: {} }, { before: {}, after: {} }], type = "delay", vehicleType = "Truck" } = props;
  console.log("SmallStat()-type:", type);
  const getValue = (props) => {
    console.log("Get-Value");
    console.log("props", props);
    const { type, vehicleType, scenario } = props;
    let vehicleIndex = [0];
    if (vehicleType === "Truck") vehicleIndex = [0];
    if (vehicleType === "Passenger Vehicle") vehicleIndex = [1];
    if (vehicleType === "All") vehicleIndex = [0, 1];


    const res = vehicleIndex.map((id) => outputs[id][scenario]);

    console.log("res:", res);
    console.log("type:", type);
    const value = res.reduce((prev, curr) => {
      return prev + curr[type];
    }, 0);
    if (isNaN(value)) return;
    return value;
  };

  /**
   * Get the label of the selected type
   * @param {string} type 
   * @returns 
   */
  const getTitle = (type) => {

    return columns.find((col) => col.name === type).label;

  };
  const formatNumber = ({ value, digits = 0, suffix = "", prefix = "" }) => {
    if (value === undefined) return "-";
    if (isNaN(value)) return "-";
    if (value === null) return "-";
    if (value !== undefined) {
      const rounded = parseFloat(value.toFixed(digits));
      if (Number.isInteger(rounded) && digits !== 0) return prefix + rounded.toLocaleString("en-US") + ".0" + suffix;
      else return prefix + rounded.toLocaleString("en-US") + suffix;
    }

    return "-";
  };
  const before = getValue({ type, vehicleType, scenario: "before" });
  const after = getValue({ type, vehicleType, scenario: "after" });
  const changeRate = after && (after / before - 1) * 100;
  console.log("before: %s, after: %s, change: %s", before, after, changeRate);

  console.log("SmallStat-outputs:", outputs);
  var classes = useStyles();
  // Get the classname of the arrow icon based on the value
  const getArrowClass = (value) => {
    if (!value) return classes.arrow;
    if (value > 0) return classes.arrowUp;
    if (value < 0) return classes.arrowDown;
  };
  //var theme = useTheme();

  // local
  //var [value, setValue] = useState("daily");

  return (
    <Widget
      header={
        <div className={classes.title}>
          <Typography variant="h5">{product}</Typography>
          <Typography variant="h7">{getTitle(type)}</Typography>

        </div>
      }
      upperTitle
      bodyClass={classes.bodyWidgetOverflow}
    >

      <div className={classes.bottomStatsContainer}>
        <div className={classnames(classes.statCell, classes.borderRight)}>
          <Grid container alignItems="center">
            <Typography variant="h7">{/*registrations[value].value*/ formatNumber({ value: before })}</Typography>
            {/* <ArrowForwardIcon
              className={classnames(classes.profitArrow, {
                [!registrations[value].profit]: classes.profitArrowDanger,
              })}
            /> */}
          </Grid>
          <Typography size="sm" color="text" colorBrightness="secondary">
            Before
          </Typography>
        </div>
        <div className={classes.statCell}>
          <Grid container alignItems="center">
            <Typography variant="h7">{
              // bounce[value].value
              formatNumber({ value: changeRate, digits: 1, suffix: "%" })
            }</Typography>

            <ArrowForwardIcon
              fontSize="small"
              className={classnames(getArrowClass(changeRate))}
            />

          </Grid>
          <Typography size="sm" color="text" colorBrightness="secondary">
            Change Rate
          </Typography>
        </div>
        <div className={classnames(classes.statCell, classes.borderRight)}>
          <Grid container alignItems="center">
            <Typography variant="h7">
              {formatNumber({ value: after })}

              {/* {registrations[value].value * 10} */}
            </Typography>
            {/* <ArrowForwardIcon
              className={classnames(classes.profitArrow, {
                [classes.profitArrowDanger]: !registrations[value].profit,
              })}
            /> */}
          </Grid>
          <Typography size="sm" color="text" colorBrightness="secondary">
            After
          </Typography>
        </div>
      </div>
    </Widget>
  );
}

// #######################################################################

function getRandomData() {
  return Array(7)
    .fill()
    .map(() => ({ value: Math.floor(Math.random() * 10) + 1 }));
}
