import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Fab,
  Grid,
  Box,
  Tooltip,
  Link
} from "@mui/material";
import {
  Menu as MenuIcon,
  MailOutline as MailIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  Send as SendIcon,
  ArrowBack as ArrowBackIcon,
  EmailOutlined as EmailIcon,
} from "@mui/icons-material";

import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import { Badge, Typography, Button } from "../Wrappers";
import Notification from "../Notification/Notification";
import UserAvatar from "../UserAvatar/UserAvatar";
import AlertDialog from "../AlertDialog/AlertDialog";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";

//Header Constants
const HeaderConstants = {
  "WHAT'S_NEW_BTN": "What's New ?",
  "UPGRADE_MSG": "The website was last updated on Aug 25, 2023.",
  "FIXIT_FACILITY": "FIXiT Facility"
};

export default function Header(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  var [mailMenu, setMailMenu] = useState(null);
  var [isMailsUnread, setIsMailsUnread] = useState(true);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  var [profileMenu, setProfileMenu] = useState(null);
  var [isSearchOpen, setSearchOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <AppBar position="fixed" className={classes.appBar} sx={{
    zIndex: 1301,          
   }}>
      <Toolbar className={classes.toolbar} disableGutters={true}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <Grid container justify="center">
            <Grid item xs={1} className={classes.logo}>
              <Link
                href="https://mobility.tamu.edu/"
                target="_blank"
                rel="noreferrer"
              >
                <Box
                  component="img"
                  sx={{
                    height: 45,
                  }}
                  alt="Your logo."
                  src={"TTI-Color-reverse.png"}
                />
              </Link>
            </Grid>

            <Grid
              item
              xs={8}
              align="center"
              justify="center"
              sx={{ padding: 0, margin: 0 }}
            >
              <Box
                height="100%"
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                <Typography
                  variant="h4"
                  noWrap              
                >
                  FIXiT
                </Typography>
              </Box>
            </Grid>

            {/*Added "FIXiT Facility" button on the header. */}
            <Grid
              item
              xs={1}
              align="center"
              justify="center"
              sx={{ padding: 0, margin: 0 }}
            >
              <Box 
                height="100%"
                display="flex"
                justifyContent="center"
                flexDirection="column">  
              <Button onClick={()=>navigate("/dashboard")} sx={{ color: "white" }}>
              {HeaderConstants["FIXIT_FACILITY"]}
              </Button> 
              
              </Box>
            </Grid>

            {/*Added "What's New" button on the header. */}
            <Grid
              item
              xs={1}
              align="center"
              justify="center"
              sx={{ padding: 0, margin: 0 }}
            >
              <Box 
                height="100%"
                display="flex"
                justifyContent="center"
                flexDirection="column">   
              <AlertDialog title={HeaderConstants["WHAT'S_NEW_BTN"]} msg={HeaderConstants["UPGRADE_MSG"]}/>
              </Box>
            </Grid>

            <Grid
              item
              xs={1}
              align="center"
              justify="center"
              sx={{ padding: 0, margin: 0 }}
            >
              <Box 
                height="100%"
                display="flex"
              justifyContent="center"
                  flexDirection="column">
              <Tooltip
                title={
                  <h1 style={{ fontSize: 14 }}>
                    Contact Us. If reporting an error, please include any error
                    messages and a copy of the settings in the email. Thanks!
                  </h1>
                }
              >
                <Link
                  href="mailto: fixittool@tti.tamu.edu"
                  target="_blank"
                  rel="noreferrer"
                >
                  {/* <Box
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                > */}
                  {/* <Button variant="outlined" onClick={handleClickOpen}> */}
                  <EmailIcon fontSize="large"  sx={{color:"white"}}/>
                  {/* </Button> */}
                  {/* </Box> */}
                </Link>
              </Tooltip>
                
              </Box>
             
            </Grid>
          </Grid>
        <div className={classes.grow} />
        {/* <Button component={Link} href="https://flatlogic.com/templates/react-material-admin-full" variant={"outlined"} color={"secondary"} className={classes.purchaseBtn}>Unlock full version</Button>
        <div
          className={classNames(classes.search, {
            [classes.searchFocused]: isSearchOpen,
          })}
        >
          <div
            className={classNames(classes.searchIcon, {
              [classes.searchIconOpened]: isSearchOpen,
            })}
            onClick={() => setSearchOpen(!isSearchOpen)}
          >
            <SearchIcon classes={{ root: classes.headerIcon }} />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
        </div>
        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={e => {
            setNotificationsMenu(e.currentTarget);
            setIsNotificationsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
          <Badge
            badgeContent={isNotificationsUnread ? notifications.length : null}
            color="warning"
          >
            <NotificationsIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton>
        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={e => {
            setMailMenu(e.currentTarget);
            setIsMailsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
          <Badge
            badgeContent={isMailsUnread ? messages.length : null}
            color="secondary"
          >
            <MailIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="mail-menu"
          open={Boolean(mailMenu)}
          anchorEl={mailMenu}
          onClose={() => setMailMenu(null)}
          MenuListProps={{ className: classes.headerMenuList }}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              New Messages
            </Typography>
            <Typography
              className={classes.profileMenuLink}
              component="a"
              color="secondary"
            >
              {messages.length} New Messages
            </Typography>
          </div>
          {messages.map(message => (
            <MenuItem key={message.id} className={classes.messageNotification}>
              <div className={classes.messageNotificationSide}>
                <UserAvatar color={message.variant} name={message.name} />
                <Typography size="sm" color="text" colorBrightness="secondary">
                  {message.time}
                </Typography>
              </div>
              <div
                className={classNames(
                  classes.messageNotificationSide,
                  classes.messageNotificationBodySide,
                )}
              >
                <Typography weight="medium" gutterBottom>
                  {message.name}
                </Typography>
                <Typography color="text" colorBrightness="secondary">
                  {message.message}
                </Typography>
              </div>
            </MenuItem>
          ))}
          <Fab
            variant="extended"
            color="primary"
            aria-label="Add"
            className={classes.sendMessageButton}
          >
            Send New Message
            <SendIcon className={classes.sendButtonIcon} />
          </Fab>
        </Menu>
        <Menu
          id="notifications-menu"
          open={Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}
          onClose={() => setNotificationsMenu(null)}
          className={classes.headerMenu}
          disableAutoFocusItem
        >
          {notifications.map(notification => (
            <MenuItem
              key={notification.id}
              onClick={() => setNotificationsMenu(null)}
              className={classes.headerMenuItem}
            >
              <Notification {...notification} typographyVariant="inherit" />
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              John Smith
            </Typography>
            <Typography
              className={classes.profileMenuLink}
              component="a"
              color="primary"
              href="https://flatlogic.com"
            >
              Flalogic.com
            </Typography>
          </div>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Profile
          </MenuItem>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Tasks
          </MenuItem>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Messages
          </MenuItem>
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => signOut(userDispatch, props.history)}
            >
              Sign Out
            </Typography>
          </div>
        </Menu> */}
      </Toolbar>
    </AppBar>
  );
}
