import { useState, useCallback } from "react";


/**
 * Customized hook for calling api
 * @param {string} method - Http request method, could be get/post. 
 * @param {string} url - Http request url
 * @returns 
 */
const useFetch = (method, url) => {
    const [isLoading, setIsLoading] = useState(false);
    const [serverResponse, setServerResponse] = useState(null);
    const [serverError, setServerError] = useState(null);
    const batchRequest = useCallback(async (bodies) => {
        if (!Array.isArray(bodies)) {
            console.log("Error-callAPIBatch(): The input is not valid.")
            setServerError(("Error-callAPIBatch(): The input is not valid."));
            setIsLoading(false);
            return;
        }
        const checkStatus = (response) => {
            if (response.ok) {
                console.log("useFetch():response.ok", response);
                return Promise.resolve(response.json());
            } else {
                console.log("useFetch():response.failed", response.statusText);
                return Promise.reject(Promise.resolve(response.json()));
            }
        };
        setIsLoading(true);
        await Promise.all(bodies.map(async (body) => {
            if (body === false) return false;
            return await fetch(url, {
                method: method,
                body: body,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            })
                .then(checkStatus)
                .catch(async (e) => {
                    console.log('There was a problem while calling the api!', await e);
                    return await e;
                }
                );
        })).then((data) => {
            console.log(data);
            if (data[0].error_msg) {
                setServerResponse(null);
                setIsLoading(false);
                setServerError(data[0].error_msg);
            }
            if (!data[0].error_msg) {
                setServerResponse(data);
                setIsLoading(false);
                setServerError(null);
            }
        }).catch((e) => {
            console.log("Error-Promise.all:", e);
        });
    }, [url, method]);

    const singleRequest = useCallback(async (body) => {
        await fetch(url, {
            method: method,
            body: body,
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((response) => {
                console.log("response:", response);
                if (response.status === 200) {
                    response.json()
                        .then((data) => {
                            if (response.ok) {
                                console.log("The request is successful and the response is ", data)
                                setServerResponse(data);
                                setServerError(null);
                            }
                            else {

                                setServerError({ status: response.status, message: data });
                                setServerResponse(null);
                            }
                            setIsLoading(false);
                        });
                    return;
                }
                if (response.status === 500) {
                    console.log("The request status is 500");
                    response.json().then((data) => {
                        console.log("500-data:", data);
                        setServerError({ status: response.status, message: data });
                        setServerResponse(null);
                    });
                    setIsLoading(false);
                    return;

                }

                if (response.status === 406) {
                    console.log("The request status is 406");
                    response.json().then((data) => {
                        console.log("406-data:", data);
                        setServerError({ status: response.status, message: data });
                        setServerResponse(null);
                    });
                    setIsLoading(false);
                    return;
                }
                setServerError({ status: response.status, message: response.statusText });
                setServerResponse(null);
                setIsLoading(false);

                return;
            })
            .catch((e) => {
                //setServerError("something");
                setServerError({ status: e.status, message: "The server is not reachable, please contact the admin." });
                setServerResponse(null);

                console.log("Fetch Error:", e);
            });

    }, [url, method]);

    const setRequest = useCallback(async ({ body, type = "single" }) => {
        console.log("Call api:", url);
        console.log("setRequest(body)-body:", body);
        setIsLoading(true);
        if (type === "batch") {
            batchRequest(body);
            return;
        }

        if (type === "single") {
            singleRequest(body);
            return;
        }


    }, [url, method]);

    return { setRequest, isLoading, serverResponse, serverError };
};

export default useFetch;