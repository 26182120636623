export const TREATMENT_TYPE_OPTIONS = [
  {
    id: 'No Treatment',
    name: 'No Treatment',
    label: 'No Treatment',
    definition: '',
    multiplier: 0,
    url: ""
  },
  {
    id: 'Add new lanes',
    name: 'Add new lanes',
    label: 'Add new lanes',
    definition: 'Widening a street or highway to add capacity to corridor.',
    multiplier: 1,
    url: "https://static.tti.tamu.edu/tti.tamu.edu/documents/policy/congestion-mitigation/adding-new-lanes-or-roads.pdf"
  },
  {
    id: 'Access management',
    name: 'Access management',
    label: 'Access management',
    definition: 'Controlling access to roadway or highway, such installation of a median to reduce the number of locations that vehicles are able to make a left turn ',
    multiplier: 0.2,
    url: "https://static.tti.tamu.edu/tti.tamu.edu/documents/policy/congestion-mitigation/access-management.pdf"
  },
  {
    id: 'Auxiliary lane',
    name: 'Auxiliary lane',
    label: 'Auxiliary lane',
    definition: 'Auxiliary lanes are ramp-to-ramp connections on the highway that reduce congestion by giving drivers more space and time to merge safely.',
    multiplier: 0.1,
    url: "https://static.tti.tamu.edu/tti.tamu.edu/documents/policy/congestion-mitigation/acceleration-deceleration-lanes.pdf"

  },
  {
    id: 'Director connector',
    name: 'Director connector',
    label: 'Director connector',
    definition: 'A ramp that directly connects one freeway to another freeway',
    multiplier: 0.5,
    url: ""

  },
  {
    id: 'Signal improvements',
    name: 'Signal improvements',
    label: 'Signal improvements',
    definition: 'Upgrading traffic signal equipment or improving traffic signal timing to improve traffic flow on corridor.',
    multiplier: 0.1,
    url: "https://static.tti.tamu.edu/tti.tamu.edu/documents/policy/congestion-mitigation/signal-operation-and-management.pdf"

  },
  {
    id: 'Variable speed limit',
    name: 'Variable speed limit',
    label: 'Variable speed limit',
    definition: 'Installation of electronic speed limit signs that can be changed to alert drivers of imminent congestion',
    multiplier: 0.2,
    url: "https://static.tti.tamu.edu/tti.tamu.edu/documents/policy/congestion-mitigation/variable-speed-limits.pdf"
  },
  {
    id: 'Widened shoulders',
    name: 'Widened shoulders',
    label: 'Widened shoulders',
    definition: 'Widen shoulder to full width to provide room for vehicles breakdowns and acceleration and deceleration of vehicles.',
    multiplier: 0.1,
    url: ""
  },
  {
    id: 'Adding New Toll Road',
    name: 'Adding New Toll Road',
    label: 'Adding New Toll Road',
    definition: 'Toll facilities charge a fee when the user passes a collection point.',
    multiplier: 1,
    url: "https://static.tti.tamu.edu/tti.tamu.edu/documents/policy/congestion-mitigation/adding-new-toll-roads.pdf"
  },
  {
    id: 'Aggressive Incident Clearance',
    name: 'Aggressive Incident Clearance',
    label: 'Aggressive Incident Clearance',
    definition: 'Aggressive incident clearance practices rapidly and safely remove temporary roadway obstructions. ',
    multiplier: 0.1,
    url: "https://static.tti.tamu.edu/tti.tamu.edu/documents/policy/congestion-mitigation/aggressive-incident-clearance.pdf"
  },
  {
    id: 'Braided ramps',
    name: 'Braided ramps',
    label: 'Braided ramps',
    definition: 'Braided ramps are ramps that cross over each other and are vertically separated by concrete pillars that elevate one or more of them.',
    multiplier: 0.15,
    url: ""
  },
  {
    id: 'Chain-up areas',
    name: 'Chain-up areas',
    label: 'Chain-up areas',
    definition: 'A designated area on highwways where drivers can install or remove snow chains.',
    multiplier: 0.05,
    url: ""
  },
  {
    id: 'Extended deceleration/acceleration lanes (with/without traffic separation)',
    name: 'Extended deceleration/acceleration lanes (with/without traffic separation)',
    label: 'Extended deceleration/acceleration lanes (with/without traffic separation)',
    definition: 'Acceleration/deceleration lanes provide drivers a safe opportunity to speed up or slow down.',
    multiplier: 0.1,
    url: "https://static.tti.tamu.edu/tti.tamu.edu/documents/policy/congestion-mitigation/acceleration-deceleration-lanes.pdf"
  },
  {
    id: 'Grade Separation',
    name: 'Grade Separation',
    label: 'Grade Separation',
    definition: 'Providing overpasses or underpasses, known as grade separation, allows traffic to flow freely.',
    multiplier: 0.1,
    url: "https://static.tti.tamu.edu/tti.tamu.edu/documents/policy/congestion-mitigation/grade-separation.pdf"
  },
  {
    id: 'Intersection Improvements',
    name: 'Intersection Improvements',
    label: 'Intersection Improvements',
    definition: 'Intersection improvements can increase safety and capacity, reducing congestion on the road.',
    multiplier: 0.1,
    url: "https://static.tti.tamu.edu/tti.tamu.edu/documents/policy/congestion-mitigation/intersection-improvements.pdf"
  },
  {
    id: 'Managed Lanes',
    name: 'Managed Lanes',
    label: 'Managed Lanes',
    definition: 'Managed lanes provide special access to vehicles based on a set of rules, usually occupancy and price.',
    multiplier: 1,
    url: "https://static.tti.tamu.edu/tti.tamu.edu/documents/policy/congestion-mitigation/managed-lanes.pdf"
  },
  {
    id: 'Ramp metering',
    name: 'Ramp metering',
    label: 'Ramp metering',
    definition: 'Ramp flow control (also known as ramp metering) uses red lights on entrance ramps to let vehicles onto a freeway in a smooth, even manner.',
    multiplier: 0.15,
    url: "https://static.tti.tamu.edu/tti.tamu.edu/documents/policy/congestion-mitigation/ramp-flow-control.pdf"
  },
  {
    id: 'Reversible Travel Lanes',
    name: 'Reversible Travel Lanes',
    label: 'Reversible Travel Lanes',
    definition: 'Reversible traffic lanes change direction based on peak congestion times.',
    multiplier: 1,
    url: "https://static.tti.tamu.edu/tti.tamu.edu/documents/policy/congestion-mitigation/reversible-traffic-lanes.pdf"
  },
  {
    id: 'Temporary Shoulder Use',
    name: 'Temporary Shoulder Use',
    label: 'Temporary Shoulder Use',
    definition: 'Temporary shoulder use allows vehicles to drive on the shoulder at reduced speed limits.',
    multiplier: 0.5,
    url: "https://static.tti.tamu.edu/tti.tamu.edu/documents/policy/congestion-mitigation/temporary-shoulder-use.pdf"
  },
  {
    id: 'Truck climbing lanes',
    name: 'Truck climbing lanes',
    label: 'Truck climbing lanes',
    definition: 'An additional lane in the uphill direction for slow moving trucks.',
    multiplier: 0.1,
    url: ""
  },
  {
    id: 'Variable advisory speed/curve warning signs',
    name: 'Variable advisory speed/curve warning signs',
    label: 'Variable advisory speed/curve warning signs',
    definition: 'Variable speed limits use speed limit signs that change to slow drivers during adverse weather or to avoid traffic congestion.',
    multiplier: 0.05,
    url: "https://static.tti.tamu.edu/tti.tamu.edu/documents/policy/congestion-mitigation/variable-speed-limits.pdf"
  },
  {
    id: 'Variable message signs',
    name: 'Variable message signs',
    label: 'Variable message signs',
    definition: '',
    multiplier: 0.1,
    url: ""
  },
  {
    id: 'Weather-responsive VAS systems',
    name: 'Weather-responsive VAS systems',
    label: 'Weather-responsive VAS systems',
    definition: 'Traveler information systems update drivers on current roadway conditions and other information important to their trips.',
    multiplier: 0.1,
    url: "https://static.tti.tamu.edu/tti.tamu.edu/documents/policy/congestion-mitigation/traveler-information-systems.pdf"
  },
  {
    id: 'Other treatment',
    name: 'Other treatment',
    label: 'Other treatment',
    definition: '',
    multiplier: 0.1,
    url: ""
  }
];

export const OUTPUT_ELEMENTS = [
  {
    id: "annual-hours-of-delay",
    unit: "person-hours",
    name: "delay",
    label: "Annual Hours of Delay",
  },


  {
    id: "peak-period-annual-hours-of-delay",
    unit: "person-hours",
    name: "peak_period_delay",
    label: "Peak Period Annual Hours of Delay",
  },

  {
    id: "co2-emission",
    dimension: "weight",
    name: "co2",
    label: "CO2 Emission",
    toMetric: x => x * 0.453592,
    fromMetric: x => x / 0.453592,

  },
  /*{
    id: "co2-emission-by-delay",
    dimension: "weight",
    name: "co2_by_delay",
    label: "CO2 Emission for Delay",
    toMetric: x => x * 0.453592,
    fromMetric: x => x / 0.453592,
  },
  {
    id: "wasted-fuel",
    dimension: "volume",
    name: "wasted_fuel",
    label: "Wasted Fuel",
    toMetric: x => x * 3.78541,
    fromMetric: x => x / 3.78541,
  },*/
  {
    id: "cost-of-delay",
    unit: "$",
    prefix: "$",
    name: "cost_of_delay",
    label: "Cost of Delay",
  },
];