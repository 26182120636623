import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { OUTPUT_ELEMENTS as columns } from '../../../../constants/UIConstants';


const formatNumber = ({ value, digits = 2, suffix = "", prefix = "" }) => {
    if (value === undefined) return "-";
    if (isNaN(value)) return "-";
    if (value === null) return "-";
    if (value !== undefined) {
        const rounded = parseFloat(value.toFixed(digits));
        if (Number.isInteger(rounded) && digits !== 0) return prefix + rounded.toLocaleString("en-US") + ".0" + suffix;
        else return prefix + rounded.toLocaleString("en-US") + suffix;
    }



    return "-";
};

const HiddenRow = (props) => {
    const { open } = props;
    const { rows, units } = props;
    return open && <React.Fragment>
        <TableRow>
            <TableCell colSpan={8}>
                Details
            </TableCell>
        </TableRow>
        <HeadRow firstColumn="Scenario" units={units}></HeadRow>
        {rows.map((row) => {
            return <TableRow>
                <TableCell></TableCell>
                <TableCell component="th" scope="row">
                    {row.scenario}
                </TableCell>
                {columns.map((col) => {
                    return <TableCell align="right"> {formatNumber({ value: row[col.name], digits: 0 })} </TableCell>
                })}
            </TableRow>
        })}


    </React.Fragment>
};

const createEmptyRow = (init) => {
    let emptyRow = { ...init };
    columns.forEach((col) => {
        emptyRow[col.name] = 0;
    });
    return emptyRow;
};



/**
 * This function calculates the summation of the previous value and the current value for each item specified in the columns.
 * @param {number} prev Base value
 * @param {number} curr Compared value
 * @returns {object} The change rate in percentage
 */
const addRows = (prev, curr) => {
    let row = { ...prev };
    columns.forEach((col) => {
        const currVal = curr[col.name];
        const prevVal = prev[col.name];
        if (!isNaN(currVal)) row[col.name] = prevVal + currVal;

    });
    return row;
};


/**
 * This function calculates the change rate between the base value and the compared value for each item specified in the columns.
 * @param {number} prev Base value
 * @param {number} curr Compared value
 * @returns {object} The change rate in percentage
 */
const getChangeRate = (prev, curr) => {
    let row = { ...prev };
    columns.forEach((col) => {
        const currVal = curr[col.name];
        const prevVal = prev[col.name];
        if (!isNaN(currVal)) row[col.name] = (currVal / prevVal - 1) * 100;
    });
    return row;
}


/**
 * This function creates the data row for the collapse table 
 */
const createDataRows = (rows) => {
    const all = {
        before: rows.filter((row) => row.scenario === "before").reduce(addRows, createEmptyRow({ type: "Total", scenario: "before" })),
        after: rows.filter((row) => row.scenario === "after").reduce(addRows, createEmptyRow({ type: "Total", scenario: "after" })),
    };
    const truck = {
        before: rows.filter((row) => row.scenario === "before" && row.type === "Truck").reduce(addRows, createEmptyRow({ type: "Truck", scenario: "before" })),
        after: rows.filter((row) => row.scenario === "after" && row.type === "Truck").reduce(addRows, createEmptyRow({ type: "Truck", scenario: "after" })),
    };
    const passenger = {
        before: rows.filter((row) => row.scenario === "before" && row.type === "Passenger Vehicle").reduce(addRows, createEmptyRow({ type: "Passenger Vehicle", scenario: "before" })),
        after: rows.filter((row) => row.scenario === "after" && row.type === "Passenger Vehicle").reduce(addRows, createEmptyRow({ type: "Passenger Vehicle", scenario: "after" })),
    };
    const newRows = [
        {
            ...getChangeRate(passenger.before, passenger.after),
            expandedRows: [passenger.before, passenger.after],
        },

        {
            ...getChangeRate(truck.before, truck.after),
            expandedRows: [truck.before, truck.after],
        },

        {
            ...getChangeRate(all.before, all.after),
            expandedRows: [all.before, all.after]
        }
    ];
    return newRows;
};



const createHiddenRows = (rows) => {

};



function Row(props) {
    const { row, units } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.type}
                </TableCell>
                {columns.map((col) => {
                    return <TableCell align="right">{formatNumber({ value: row[col.name], digits: 1, suffix: "%" })}</TableCell>
                })}

            </TableRow>
            <HiddenRow rows={row.expandedRows} open={open} units={units}></HiddenRow>


        </React.Fragment>
    );
}



const HeadRow = (props) => {
    const { firstColumn = "", units = { weight: "lbs", volume: "litters", speed: "mph" } } = props;
    console.log("HeadRow-columns:", columns);
    return <TableRow>
        <TableCell></TableCell>
        <TableCell> {firstColumn}</TableCell>
        {columns.map((col) => {
            return <TableCell align="right"> {col.label} ({col.unit}{col.dimension !== undefined ? units[col.dimension] : ""}) </TableCell>
        })}
    </TableRow>
}




export default function CollapsibleTable(props) {
    const { rows = [], groupBy = ["type", "scenario"], units } = props;

    console.log("units", units);
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <HeadRow firstColumn="Vehicle Type" units={units}></HeadRow>
                </TableHead>
                <TableBody>
                    {createDataRows(rows).map((row) => (
                        <Row key={row.name} row={row} units={units} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}