import { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import clustermap from "../../../../images/clustermap.png";
import { FormControl, FormControlLabel, Switch } from '@mui/material';
import { DAY_TYPE as dayTypes, CONGESTION_LEVEL as congestionLevels, CLIMATE_CLUSTER as climateClusters, PEAKING_DIRECTION as peakingDirections } from '../../../../constants/DictionaryConstants';
import Stack from '@mui/material/Stack';
import { useGlobalUpdater } from '../../../../context/GlobalContext';


const EditDialog = ({ title = "Edit", data = {} }) => {

  const [open, setOpen] = useState(false);
  const updater = useGlobalUpdater()
  const [state, setState] = useState({});
  console.log("statein EditDialog:", state);
  const items = [
    {
      id: "volume-profile-daytype",
      name: "dayType",
      label: "Day Type",
      type: "select",
      placeHolder: "Please select day type(weekday/weekend)",
      gridSpan: 3,
      options: dayTypes.map((option) => (
        <MenuItem key={option.value} value={option.label}>
          {option.label}
        </MenuItem>
      )),

    },
    {
      id: "volume-profile-peaking-direction",
      label: "Peaking Direction",
      type: "select",
      name: "peakingDirection",
      gridSpan: 3,
      options: peakingDirections.map((option) => (
        <MenuItem key={option.value} value={option.label}>
          {option.label}
        </MenuItem>
      )),
    },
    {
      id: "volume-profile-congestion-level",
      name: "congestionLevel",
      label: "Congestion Level",
      type: "select",
      placeHolder: "Please select the congestion level",
      gridSpan: 3,
      options: congestionLevels.map((option) => (
        <MenuItem key={option.value} value={option.label}>
          {option.label}
        </MenuItem>
      )),
    },
    {


      id: "climate-profile-climate-cluster",
      name: "climateCluster",
      label: "Climate Cluster",
      type: "select",
      placeHolder: "Please select the climate cluster",
      gridSpan: 3,
      options: climateClusters.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      )),
    },
  ];
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = () => {
    items.forEach((item) => {
      const name = item.name;
      // const value = stateRef.current[name];
      const value = state[name];
      updater({ type: "updateBeforeScenarioInputs", name, value });
      updater({ type: "updateAfterScenarioInputs", name, value });
    });
    handleClose();
  };
  const handleCancel = () => {
    handleClose();
    setState(data);
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });

  };
  useEffect(() => {
    //stateRef.current = data||{};

    setState({ ...data });
  }, [data]);
  return (
    <div>
      <IconButton size="medium">
        <EditIcon
          fontSize="inherit"
          onClick={handleClickOpen}
        ></EditIcon>
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="md" sx={{

        mt: 10,
      }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To calculate the impact of the project, please enter the optional information below.
          </DialogContentText>
          <Grid container spacing={2}>
            {items.map((item) => {
              const { type, id, name, unit, options, disabled, placeholder, gridSpan, label } = item;
              const formatter = item.formatter ? item.formatter : (val) => (type === "number" ? parseFloat(val) : val);
              return <Grid item xs={gridSpan}>
                <Stack direction="column"
                  justifyContent="left"
                  alignItems="left"
                  spacing={0} >
                  <Typography >{label}</Typography>
                  <TextField
                    id={id}
                    type={type}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="start">{unit}</InputAdornment>,
                    }}
                    select={options}
                    disabled={disabled}
                    name={name}
                    onChange={handleChange}

                    value={formatter(state[name]) !== undefined ? formatter(state[name]) : ''}
                    variant="standard" placeholder={placeholder}>
                    {options}
                  </TextField>
                </Stack>
              </Grid>
            })}
            <Grid item xs={12} >




            </Grid>
            <Grid item xs={12}>
              <Stack direction="column" alignItems={"center"}>
                <img src={clustermap} alt="google" />
                <Typography>Figure 1. Climate clusters</Typography>
              </Stack>

            </Grid>


          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSave}>Save Changes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default EditDialog;
