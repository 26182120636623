const { BlobServiceClient } = require("@azure/storage-blob");
const containerName = "facility-documents"
const connectionString = process.env.REACT_APP_AZURE_STORAGE_CONNECTION_STRING;
const blobServiceClient = BlobServiceClient.fromConnectionString(connectionString);

export async function saveFile(fileName, type) {
  const containerClient = blobServiceClient.getContainerClient(containerName);
  const blobName = type + "/" + fileName;
  const blobClient = containerClient.getBlobClient(blobName);
  const downloadBlockBlobResponse = await blobClient.download();
  const url = window.URL.createObjectURL(
    await downloadBlockBlobResponse.blobBody
  );
  downloadURI(url, blobName);
  console.log("Downloaded blob to string content", url);
  function downloadURI(uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.click();
  }

}
