import React, { useState, useRef } from "react";
import { Paper, IconButton, Menu, MenuItem, Typography } from "@mui/material";
// import { MoreHoriz as MoreIcon } from "@mui/icons-material";
import { Edit as ClickableIcon } from "@mui/icons-material";
import classnames from "classnames";

// styles
import useStyles from "./styles";

export default function Widget({
  children,
  title,
  noBodyPadding,
  bodyClass,
  disableWidgetMenu,
  header,
  noHeaderPadding,
  headerClass,
  style,
  noWidgetShadow,
  dialogComponent,
  toolBar,
  ...props
}) {
  const classes = useStyles();

  // local
  const [moreButtonRef, setMoreButtonRef] = useState(null);
  const [isMoreMenuOpen, setMoreMenuOpen] = useState(false);
  const open = Boolean(moreButtonRef);

  const handleMoreButtonClick = (e) => {
    setMoreButtonRef(e.currentTarget);
  };
  const handleMenuClose = (e) => {
    setMoreButtonRef(null);
  };
  return (
    <div className={classes.widgetWrapper} style={style && { ...style }}>
      <Paper
        className={classes.paper}
        classes={{
          root: classnames(classes.widgetRoot, {
            [classes.noWidgetShadow]: noWidgetShadow,
          }),
        }}
      >
        <div
          className={classnames(classes.widgetHeader, {
            [classes.noPadding]: noHeaderPadding,
            [headerClass]: headerClass,
          })}
        >
          {header ? (
            header
          ) : (
            <React.Fragment>
              <Typography variant="h5" color="textSecondary" noWrap>
                {title}
              </Typography>
              {toolBar}
              {!disableWidgetMenu &&
                (dialogComponent ? (
                  dialogComponent
                ) : (
                  <IconButton
                    color="primary"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    classes={{ root: classes.moreButton }}
                    // aria-owns="widget-menu"
                    aria-haspopup="true"
                    onClick={handleMoreButtonClick}
                  >
                    <ClickableIcon />
                  </IconButton>
                ))}
            </React.Fragment>
          )}
        </div>
        <div
          className={classnames(classes.widgetBody, {
            [classes.noPadding]: noBodyPadding,
            [bodyClass]: bodyClass,
          })}
        >
          {children}
        </div>
      </Paper>
      <Menu
        id="widget-menu"
        open={open}
        anchorEl={moreButtonRef}
        onClose={handleMenuClose}
        disableAutoFocusItem
        disableScrollLock={true}
      >
        <MenuItem onClick={handleMenuClose}>
          <Typography>Edit</Typography>
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <Typography>Copy</Typography>
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <Typography>Delete</Typography>
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <Typography>Print</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}
