import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiRef
} from '@mui/x-data-grid';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import useStyles from './styles';
import Papa from 'papaparse';
import { useGlobalUpdater } from '../../../../context/GlobalContext';
const loadSelectedCsv = (onLoad, blob) => {
  var reader = new FileReader();
  reader.onload = function () {
    // let fileData = JSON.parse(reader.result);
    // setBeforeScenarioInputs(fileData.beforeScenarioInputs);
    // setAfterScenarioInputs(fileData.afterScenarioInputs);
    console.log("reader.result-check", reader.result);

    Papa.parse(reader.result, {
      download: false,
      header: true,
      complete: function (input) {
        // console.log("input-check", input);
        const storageRecords = input.data;
        console.log("storageRecords-check", storageRecords);
        let dataTemp = [];
        storageRecords.map((obj) => {
          //Added criteria to exclude empty row in the end, because saving csv in EXCEl may add an empty row in the end. 
          if (obj.Time !== "") dataTemp.push({ time: obj.Time, speed: parseFloat(obj.Speed) });
        });

        // setBeforeScenarioInputs({
        //   ...query.beforeScenarioInputs,
        //   ["speeds"]: dataTemp,
        // });
        console.log("storageRecords-checkDataTemp", dataTemp);

        onLoad(dataTemp);
        // setBeforeSpeedsTableData(dataTemp);
      },
    });

  };
  reader.readAsText(blob);
};

const RowMenuCell = (props) => {
  const classes = useStyles();

  const { api, id } = props;

  const isInEditMode = api.getCellMode(id, "speed") === 'edit';

  const field = "speed";
  const handleEditClick = (event) => {
    event.stopPropagation();
    //console.log("Row(%s) mode: ", id, api.getCellMode({ id, field }));
    api.startCellEditMode({ id, field: "speed" });
  };

  const handleSaveClick = (event) => {
    event.stopPropagation();
    api.stopCellEditMode({ id, field, ignoreModifications: false });
    // const params = api.getCellParams(id, field);

    // Fire the event for cellEditStop to trigger onCellEditStop() !important
    // api.publishEvent('cellEditStop', {
    //   ...params,
    //   reason: GridCellEditStopReasons.enterKeyDown
    // });

    console.log("Save is clicked");

  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    api.updateRows([{ id, _action: 'delete' }]);
  };

  const handleCancelClick = (event) => {
    event.stopPropagation();
    api.stopCellEditMode({ id, field, ignoreModifications: true });

    //api.setRowMode(id, 'view');

    const row = api.getRow(id);
    if (row.isNew) {
      api.updateRows([{ id, _action: 'delete' }]);
    }
  };

  if (isInEditMode) {
    return (
      <div className={classes.root}>
        <IconButton
          color="primary"
          size="small"
          aria-label="save"
          onClick={handleSaveClick}
        >
          <SaveIcon fontSize="small" />
        </IconButton>
        <IconButton
          color="inherit"
          size="small"
          aria-label="cancel"
          className={classes.textPrimary}
          onClick={handleCancelClick}
        >
          <CancelIcon fontSize="small" />
        </IconButton>
      </div>
    );
  };
  return (
    <div className={classes.root}>
      <IconButton
        color="inherit"
        className={classes.textPrimary}
        size="small"
        aria-label="edit"
        onClick={handleEditClick}
      >
        <EditIcon fontSize="small" />
      </IconButton>

    </div>
  );
};

RowMenuCell.propTypes = {
  api: PropTypes.object.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

const GridToolbarImport = ({ onLoad }) => {
  return <Button component="label" startIcon={<UploadFileIcon></UploadFileIcon>}>
    Import
    <input
      accept=".csv"
      id="contained-button-file-speeds-csv"
      // multiple
      type="file"
      hidden

      onChange={(e) => loadSelectedCsv(onLoad, e.target.files[0])}
    />

  </Button>

}

const CustomToolbar = (props) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <GridToolbarImport {...props} />
    </GridToolbarContainer>
  );
}
const EditDialog = ({ title = "Edit", onSave = () => { }, data = [] }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const useMetricSystem = false;
  const apiRef = useGridApiRef();
  console.log("SpeedEditDialog-data", data);
  const CustomToolbarWrapper = () => {
    return (
      <CustomToolbar
        onLoad={setRows}
      ></CustomToolbar>
    );
  };
  // Initialize
  useEffect(() => {
    setRows(data);
  }, []);



  const updateRows = (newRow, oldRow) => {
    const key = "time";
    let newRows = [...rows];
    const rowIndex = newRows.findIndex((obj) => obj[key] === oldRow[key]);
    newRows[rowIndex] = newRow;
    setRows(newRows);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    console.log("Rows after save:", rows);
    onSave(rows);
    handleClose();
  };

  const handleCancel = () => {
    console.log("Rows after cancel:", data);
    setRows(data);
    handleClose();
  };


  const handleRowUpdate = (newRow, oldRow) => {
    console.log("Get sorted rows", apiRef.current.getSortedRows());
    console.log("processRowUpdate is fired, newRow is", newRow);
    const parsedNewRow = { ...newRow, speed: parseFloat(newRow.speed) };
    updateRows(parsedNewRow, oldRow);
    return newRow;
  };

  const handleRowChange = (props) => {
    setRows(apiRef.current.getSortedRows());
    return true;
  };
  const columns = [
    {
      field: 'time',
      headerName: 'Time',
      width: 260,
      sortable: false
    },

    {
      field: 'speed',
      headerName: 'Speed',
      width: 100,
      editable: true,
      sortable: false
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: RowMenuCell,
      sortable: false,
      width: 100,
      headerAlign: 'center',
      filterable: false,
      align: 'center',
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
    },

  ];
  useEffect(() => {
    console.log("rows:", rows);
    setRows(data);
  }, [data]);

  //console.log("Speed Profile", speedProfile);
  return (
    <div>
      <IconButton size="medium">
        <EditIcon
          fontSize="inherit"
          onClick={handleClickOpen}
        ></EditIcon>
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" sx={{

        mt: 10,
      }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To calculate the impact of the project, please enter the speed profile.
          </DialogContentText>
          <Grid container direction="column" className={classes.dataGridWrapper}>

            <DataGrid
              apiRef={apiRef}
              rows={rows}
              columns={columns}
              getRowId={(row) => row.time}
              autoHeight
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 8,
                  },
                },
              }}
              pageSizeOptions={[4, 8, 12]}
              processRowUpdate={handleRowUpdate}
              slots={{ toolbar: CustomToolbarWrapper }}
              disableRowSelectionOnClick={true}
            >

            </DataGrid>



          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSave}>Save Changes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default EditDialog;