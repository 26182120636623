import { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { SingleSelectLabel } from '../../../../ui/TreatmentSelection';
import Stack from '@mui/material/Stack';
import { FACILITY_TYPE as facilityTypes } from '../../../../constants/DictionaryConstants';
import { useGlobalUpdater } from '../../../../context/GlobalContext';


const EditDialog = ({ title = "Edit", data = {} }) => {

  const [open, setOpen] = useState(false);
  const [count,setCount] = useState(0);
  const updater = useGlobalUpdater()
  const [state,setState] = useState({});
  const useMetricSystem = state.useMetricSystem;
  console.log("statein EditDialog:", state);
  const items = [
    {
      id: "before-aadt",
      name: "aadt",
      label: "Average Daily Traffic (AADT)",
      type: "number",
      placeHolder: "Please enter AADT",
      gridSpan: 3

    },
    {
      id: "before-aadt-per-lane",
      label: "Average Daily Traffic per Lane",
      type: "number",
      name: "aadtPerLane",
      disabled: true,
      gridSpan: 3,
      formatter: (num)=>{return num?num.toFixed(0):""}
    },
    {
      id: "before-lanes",
      name: "lanes",
      label: "Lanes",
      type: "number",
      placeHolder: "Please enter the number of lanes",
      gridSpan: 6
    },

    {
      id: "before-facility-type",
      name: "facilityType",
      label: "Facility Type",
      type: "select",
      placeHolder: "Please select the facility type",
      gridSpan: 6,
      options: facilityTypes.map((option) => (
        <MenuItem key={option.value} value={option.label}>
          {option.label}
        </MenuItem>
      ))
    },

    {
      id: "before-ref-speed",
      name: "refSpeed",
      label: "Freeflow or Posted Speed",
      type: "number",
      placeHolder: "Please enter the freeflow or posted speed",
      gridSpan: 6,
      unit: useMetricSystem ? "kmph" : "mph",
    },
    {
      id: "before-length",
      name: "length",
      label: "Length",
      type: "number",
      placeHolder: "Please enter the length of the segment",
      gridSpan: 6,
      unit: useMetricSystem ? "km" : "miles",
    },
    {
      id: "before-daily-truck-percent",
      name: "dailyTruckPercent",
      label: "Daily Truck Percent",
      type: "number",
      placeHolder: "Please enter the daily truck percentage",
      gridSpan: 6,
      unit: "%",
    },


  ];
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = () => {
    items.forEach((item) => {
      const name = item.name;
      // const value = stateRef.current[name];
      const value = state[name];
      updater({ type: "updateBeforeScenarioInputs", name, value });
    });
    updater({type:"updateAfterScenarioInputs",name:"lanes",value:state.lanes});
    handleClose();
  };
  const handleCancel = ()=>{
    handleClose();
    setState(data);
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    // const cur = stateRef.current;
    
    if (name == "lanes")  {
      
      const aadtPerLane = state.aadt / value;
      console.log("aadt %d, lanes %d, aadt per lane %d", state.aadt, state.lanes, aadtPerLane);
      setState({ ...state, [name]: value,aadtPerLane});
      return;
    }
    if (name == "aadt") {
      const aadtPerLane = value / state.lanes;
      console.log("aadt %d, lanes %d, aadt per lane %d", state.aadt, state.lanes, aadtPerLane);
      setState({ ...state, [name]: value,aadtPerLane});
      return;
    }
    
    setState({ ...state, [name]: value});
    // stateRef.current = { ...cur, [name]: value };
    // setCount(count+1);
  };
  useEffect(() => {
    //stateRef.current = data||{};
    const aadtPerLane = data.aadt/data.lanes;  
    setState({...data,aadtPerLane});
  },[data]);
  return (
    <div>
      <IconButton size="medium">
        <EditIcon
          fontSize="inherit"
          onClick={handleClickOpen}
        ></EditIcon>
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" sx={{

        mt: 10,
      }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To calculate the impact of the project, please enter the required information below.
          </DialogContentText>
          <Grid container spacing={2}>
            {items.map((item) => {
              const { type, id, name, unit, options, disabled,placeholder, gridSpan, label } = item;
              const formatter = item.formatter?item.formatter:(val)=>(type==="number"? parseFloat(val):val);
              return <Grid item xs={gridSpan}>
                <Stack direction="column"
                  justifyContent="left"
                  alignItems="left"
                  spacing={0} >
                  <Typography >{label}</Typography>
                  <TextField
                    id={id}
                    type={type}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="start">{unit}</InputAdornment>,
                    }}
                    select={options}
                    disabled={disabled}
                    name={name}
                    onChange={handleChange}
            
                    value = {formatter(state[name]) !== undefined? formatter(state[name]):''}
                    variant="standard" placeholder={placeholder}>
                    {options}
                  </TextField>
                </Stack>
              </Grid>
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSave}>Save Changes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default EditDialog;