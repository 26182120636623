import DownloadIcon from "@mui/icons-material/Download";
import { saveFile } from "./SaveFile";
import { Button } from "@mui/material";
import { useState } from "react";
import DownloadNotification from "../dashboard/components/DownloadNotification/DownloadNotification";

const DownloadFiles = (props) => {

  /* Set state for notification bar */
  const [showNotification, setShowNotification] = useState(false);

  const handleOnClick = () => {
    saveFile(props.fileName, props.type);
    setShowNotification(true);
  };

  const closeNotification = () => {
    setShowNotification(false);
  };

  return (
    <div>

      {/* Download button for file. */}
      <Button
        variant="text"
        sx={{ textTransform: "none" }}
        onClick={handleOnClick}
      >
        {props.displayName}
        <DownloadIcon />
      </Button>

      {/* Display notification bar after download. */}
      <DownloadNotification
        open={showNotification}
        onClose={closeNotification}
      />
    </div>
  );
};

export default DownloadFiles;
