import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';



import { useGlobalUpdater } from '../../../../context/GlobalContext';

const items = [
  {
    id: "proj-facility-name",
    name: "facilityName",
    label: "Facility Name",
    type: "text",
    placeholder: "Please enter the facility name",
    gridSpan: 4,
  },
  {
    id: "proj-facility-location",
    name: "facilityLocation",
    label: "Facility Location",
    type: "text",
    placeholder: "Please enter the facility Location",
    gridSpan: 4,
  },
  {
    id: "proj-base-year",
    name: "baseAnalysisYear",
    label: "Base Analysis Year",
    type: "number",
    placeholder: "Please enter the base analysis year",
    gridSpan: 4,
  },
  {
    id: "proj-description",
    name: "description",
    label: "Scenario Description",
    type: "text",
    placeholder: "Please enter the scenario description",
    gridSpan: 12,
    multiline: true,
    rows: 3
  },


];

const EditDialog = ({ title = "Edit", data = {} }) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({});
  const updater = useGlobalUpdater();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    items.forEach((item) => {
      const name = item.name;
      // const value = stateRef.current[name];
      const value = state[name];
      updater({ type: "setProjectInfo", name, value });
    });
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
    setState(data);
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    setState({ ...data });
  }, [data]);
  return (
    <div>
      <IconButton size="medium">
        <EditIcon
          fontSize="inherit"
          onClick={handleClickOpen}
        ></EditIcon>
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" sx={{

        mt: 10,
      }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the basic project information.
          </DialogContentText>
          <Grid container spacing={2}>
            {items.map((item) => {
              const { gridSpan, id, label, name, type, placeholder, multiline, rows } = item;
              return (<Grid item xs={gridSpan}>
                <Stack direction="column"
                  justifyContent="left"
                  alignItems="left"
                  spacing={0} >
                  <Typography >{label}</Typography>
                  <TextField id={id} variant="standard" value={state[name]} multiline={multiline} rows={rows ? rows : 1} type={type} name={name} placeholder={placeholder} onChange={handleChange}/>
                </Stack>
              </Grid>);
            })}


          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSave}>Save Changes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default EditDialog;