import { useState, useEffect, Fragment } from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TooltipIcon from '@mui/icons-material/HelpOutline';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { FACILITY_TYPE as facilityTypes } from '../../../../constants/DictionaryConstants';
import { TREATMENT_TYPE_OPTIONS, TREATMENT_TYPE_OPTIONS as treatmentTypes } from '../../../../constants/UIConstants';
import { useGlobalUpdater, copyBeforeToAfterInputs } from '../../../../context/GlobalContext';


const getTooltipForTreatmentType = (type, dictionary) => {
  const foundItem = dictionary.find((item) => item.label === type);
  const onepagerLink = foundItem && foundItem.url ? <> CLICK <a href={foundItem.url} target="_blank" rel="noopener noreferrer">this link</a> for more information</> : "";
  const definition = foundItem && foundItem.definition != '' ? foundItem.definition : "";
  const tooltipContent = <>
    {definition} {" "}{onepagerLink}</>;

  return tooltipContent;
};


const EditDialog = ({ title = "Edit", data = {} }) => {

  const [open, setOpen] = useState(false);
  const updater = useGlobalUpdater()
  const [state, setState] = useState({});
  const [treatmentTooltip, setTreatmentTooltip] = useState(false);
  const [disabled, setDisabled] = useState({ aadtPerLane: true, lanes: true, laneAddition: true });
  const useMetricSystem = state.useMetricSystem;
  console.log("statein EditDialog:", state);
  const items = [
    {
      id: "after-aadt",
      name: "aadt",
      label: "AADT",
      type: "number",
      placeHolder: "Please enter AADT",
      gridSpan: 3

    },
    {
      id: "after-aadt-per-lane",
      label: "AADT per Lane",
      type: "number",
      name: "aadtPerLane",
      disabled: disabled.aadtPerLane,
      gridSpan: 3,
      // formatter: (num) => { return num ? num.toFixed(0) : "" }
      formatter: () => {
        const totalLanes = state.treatmentType === "Add new lanes" ? (parseFloat(state.lanes) + parseFloat(state.laneAddition)) : parseFloat(state.lanes);
        const aadtPerLane = parseFloat(state.aadt) / totalLanes;
        return aadtPerLane.toFixed(0);
      },
    },
    {
      id: "after-lanes",
      name: "lanes",
      label: "Lanes",
      type: "number",
      formatter: () => parseFloat(state.lanes) + (state.treatmentType === "Add new lanes" ? parseFloat(state.laneAddition) : 0),
      disabled: true,
      placeholder: "Please enter the number of lanes",
      gridSpan: 6
    },

    {
      id: "after-facility-type",
      name: "facilityType",
      label: "Facility Type",
      type: "select",
      placeholder: "Please select the facility type",
      gridSpan: 6,
      options: facilityTypes.map((option) => (
        <MenuItem key={option.value} value={option.label}>
          {option.label}
        </MenuItem>
      ))
    },

    {
      id: "after-ref-speed",
      name: "refSpeed",
      label: "Freeflow or Posted Speed",
      type: "number",
      placeholder: "Please enter the freeflow or posted speed",
      gridSpan: 6,
      unit: useMetricSystem ? "kmph" : "mph",
    },
    {
      id: "after-length",
      name: "length",
      label: "Length",
      type: "number",
      placeholder: "Please enter the length of the segment",
      gridSpan: 6,
      unit: useMetricSystem ? "km" : "miles",
    },
    {
      id: "after-daily-truck-percent",
      name: "dailyTruckPercent",
      label: "Daily Truck Percent",
      type: "number",
      placeholder: "Please enter the daily truck percentage",
      gridSpan: 6,
      unit: "%",
    },
    {
      id: "after-scenario-treatment-types",
      name: "treatmentType",
      label: "Treatment Type",
      type: "select",
      placeholder: "Please enter the treatment type",
      options: treatmentTypes.map((option) => (
        <MenuItem key={(option.id)} value={option.label} dense>
          {option.label}

        </MenuItem>)),
      gridSpan: 6,
      tooltip: treatmentTooltip && <Tooltip title={treatmentTooltip}><TooltipIcon fontSize="small"></TooltipIcon></Tooltip>
    },
    {
      id: "after-scenario-treatment-types-lanes",
      name: "laneAddition",
      label: "Added Lanes",
      type: "number",
      disabled: true,
      placeholder: "Please enter the daily truck percentage",
      gridSpan: 6,
      isHidden: ({ treatmentType }) => treatmentType !== 'Add new lanes',

    },


  ];
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = () => {
    items.forEach((item) => {
      const name = item.name;
      // const value = stateRef.current[name];
      const value = state[name];
      updater({ type: "updateAfterScenarioInputs", name, value });
    });
    handleClose();
  };
  const handleCancel = () => {
    handleClose();
    setState(data);
  };

  const handleLoad = () => {
    copyBeforeToAfterInputs(updater);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    // const cur = stateRef.current;

    if (name == "lanes") {

      const aadtPerLane = parseFloat(state.aadt) / (parseFloat(value));
      console.log("aadt %d, lanes %d, aadt per lane %d", state.aadt, state.lanes, aadtPerLane);
      setState({ ...state, [name]: value, aadtPerLane });
      return;
    }
    if (name == "aadt") {
      const aadtPerLane = parseFloat(value) / (parseFloat(state.lanes));
      console.log("aadt %d, lanes %d, aadt per lane %d", state.aadt, state.lanes, aadtPerLane);
      setState({ ...state, [name]: value, aadtPerLane });
      return;
    }

    if (name == "treatmentType") {
      const laneAddition = treatmentTypes.filter((item) => { return item.label === value; })[0].multiplier;
      const aadtPerLane = parseFloat(state.aadt) / (parseFloat(state.lanes) + parseFloat(laneAddition));
      if (value === "Add new lanes") setDisabled({ ...disabled, laneAddition: false });
      else setDisabled({ ...disabled, laneAddition: true });
      setState({ ...state, [name]: value, laneAddition, aadtPerLane });


      return;
    }

    if (name === "laneAddition") {

    }
    setState({ ...state, [name]: value });
    // stateRef.current = { ...cur, [name]: value };
    // setCount(count+1);
  };
  useEffect(() => {
    //stateRef.current = data||{};
    const aadtPerLane = parseFloat(data.aadt) / (parseFloat(data.lanes) + parseFloat(data.laneAddition));
    setState({ ...data, aadtPerLane });

  }, [data]);

  useEffect(() => {
    setTreatmentTooltip(getTooltipForTreatmentType(state.treatmentType, TREATMENT_TYPE_OPTIONS));
  }, [state.treatmentType]);

  return (
    <div>
      <IconButton size="medium">
        <EditIcon
          fontSize="inherit"
          onClick={handleClickOpen}
        ></EditIcon>
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="md" sx={{

        mt: 10,
      }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To calculate the impact of the project, please enter the required information below.
          </DialogContentText>
          <Grid container spacing={2}>
            {items.map((item) => {

              const formatter = item.formatter ? item.formatter : (val) => (type === "number" ? parseFloat(val) : val);
              const { type, id, name, unit, options, placeholder, gridSpan, label, tooltip, isHidden } = item;
              const hidden = isHidden ? isHidden(state) : false;
              const child = (<TextField
                id={id}
                type={type}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="start">{unit}</InputAdornment>,
                }}
                select={options}
                disabled={disabled[name]}
                name={name}
                onChange={handleChange}

                value={formatter(state[name]) !== undefined ? formatter(state[name]) : ''}
                variant="standard" placeholder={placeholder}>
                {options}
              </TextField>);
              return (!hidden && <Grid item xs={gridSpan}>
                <Stack direction="column"
                  justifyContent="left"
                  alignItems="left"
                  spacing={0} >
                  <Typography >{label} {tooltip ? tooltip : ""}</Typography>
                  {child}
                </Stack>
              </Grid>);
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLoad}>Load Before Scenario</Button>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSave}>Save Changes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default EditDialog;