  export const FACILITY_TYPE = [
    { label: "Freeway", value: "fway" },
    { label: "Non-Freeway", value: "nonFway" },
  ];
  export const VOLUME_PROFILE_FUNCTIONAL_CLASS = [
    { label: "Freeway", value: "fway" },
    { label: "Non-Freeway", value: "nonFway" },
  ];
  export const DAY_TYPE = [
    { label: "Weekday", value: "wkd" },
    { label: "Weekend", value: "wke" },
  ];
  export const PEAKING_DIRECTION = [
    { label: "AM Peak", value: "ampk" },
    { label: "PM Peak", value: "pmpk" },
    { label: "Balanced", value: "balanced" },
    { label: "Weekend", value: "wke" },
  ];
  export const CONGESTION_LEVEL = [
    { label: "Low", value: "low" },
    { label: "Moderate", value: "moderate" },
    { label: "Severe", value: "severe" },
    { label: "Weekend", value: "wke" },
  ];
  export const CLIMATE_CLUSTER = [
    { label: "1 (Low A/C Usage)", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4 (High A/C Usage)", value: 4 },
    { label: "11 (Hawaii)", value: 11 },
    { label: "12 (Alaska)", value: 12 },
    // { label: "100", value: 100 },
  ];
  export const CO2_FUNCTIONAL_CLASS = [
    { label: "Freeway", value: "fway" },
    { label: "Non-Freeway", value: "nonFway" },
  ];

  const DICTIONARY_CONSTANTS = {
    FACILITY_TYPE,VOLUME_PROFILE_FUNCTIONAL_CLASS,DAY_TYPE,PEAKING_DIRECTION,CONGESTION_LEVEL,CLIMATE_CLUSTER,CO2_FUNCTIONAL_CLASS
  };
  export default DICTIONARY_CONSTANTS;