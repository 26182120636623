/**
 * @fileOverview the global context provider component.
 */

import {
  createContext,
  useContext,
  useReducer,
  useMemo,
  useCallback,
} from "react";
import { INITIAL_INPUTS, EMPTY_INPUTS } from "../constants/DefaultConstants";
import {
  DAY_TYPE,
  FACILITY_TYPE,
  PEAKING_DIRECTION,
  CONGESTION_LEVEL,
} from "../constants/DictionaryConstants";
import { InputDataForTest } from "../test/input-data-truck";
import { InputDataForTestCar } from "../test/input-data-car";
import { OUTPUT_ELEMENTS } from "../constants/UIConstants";

const GlobalStateContext = createContext();
const GlobalUpdaterContext = createContext();

/**
 *
 * @param {Object} res the inputs
 * @returns parsed inputs
 */
const parseInputs = (res) => {
  //let parsed = structuredClone(res);
  let parsed = { ...res };
  const facility = FACILITY_TYPE;
  const dayType = DAY_TYPE;
  const congestionLevel = CONGESTION_LEVEL;
  const peakingDirection = PEAKING_DIRECTION;
  console.log("res:", res);
  parsed.congestionLevel =
    congestionLevel.find((e) => e.label === res.congestionLevel)?.label || null;
  parsed.dayType = dayType.find((e) => e.label === res.dayType)?.label || null;
  parsed.facilityType =
    facility.find((e) => e.label === res.facilityType)?.label || null;
  parsed.peakingDirection =
    peakingDirection.find((e) => e.label === res.peakingDirection)?.label ||
    null;
  parsed.refSpeed = parseInt(res.refSpeed) || null;
  parsed.speeds =
    typeof res.speeds === "undefined" ? [] : res.speeds.map((e) => e.speed);
  parsed.lanes = parseInt(res.lanes) || null;
  parsed.length = parseFloat(res.length) || null;
  parsed.laneAddition = parseFloat(res.laneAddition) || null;
  parsed.aadt = parseInt(res.aadt) || null;
  return parsed;
};

const convertFromMetric = (scenario) => {
  console.log("convertFromMetric scenario");
  console.log(scenario);
  let converted = { ...scenario };
  if (converted.lane_length) converted.lane_length *= 0.621371;
  if (converted.reference_speed) converted.reference_speed *= 0.621371;
  //if (converted.fuel_consumption) converted.fuel_consumption *= 8.3454;
  if (converted.speed_profile)
    converted.speed_profile = converted.speeds.map((speed) => speed * 0.621371);

  if (converted.length) converted.length *= 0.621371;
  if (converted.refSpeed) converted.refSpeed *= 0.621371;
  if (converted.fuel_consumption) converted.fuel_consumption *= 8.3454;
  if (converted.speeds)
    converted.speeds = converted.speeds.map(({ time, speed, tableData }) => {
      return { time, speed: speed * 0.621371, tableData };
    });
  return converted;
};

const convertToMetric = (scenario) => {
  console.log("convertToMetric scenario");
  console.log(scenario);
  let converted = { ...scenario };
  if (converted.lane_length) converted.lane_length /= 0.621371;
  if (converted.reference_speed) converted.reference_speed /= 0.621371;
  //if (converted.fuel_consumption) converted.fuel_consumption *= 8.3454;
  if (converted.speed_profile)
    converted.speed_profile = converted.speeds.map((speed) => speed / 0.621371);
  if (converted.length) converted.length /= 0.621371;
  if (converted.refSpeed) converted.refSpeed /= 0.621371;
  if (converted.fuel_consumption) converted.fuel_consumption /= 8.3454;
  if (converted.speeds)
    converted.speeds = converted.speeds.map(({ time, speed, tableData }) => {
      return { time, speed: speed / 0.621371, tableData };
    });
  return converted;
};

const convertToMetricOutput = (data) => {
  console.log("convertToMetricOutput:", data);
  // Don't do anything if there is no input
  if (!data) return data;

  let converted = { ...data };
  const afterSpeeds = converted[0].after.speed_profile.map(
    (speed) => speed / 0.621371
  );
  converted[0].after.speed_profile = afterSpeeds;
  converted[1].after.speed_profile = afterSpeeds;
  OUTPUT_ELEMENTS.forEach((elem) => {
    const { toMetric = (x) => x } = elem;
    converted[0].before[elem.name] = toMetric(converted[0].before[elem.name]);
    converted[1].before[elem.name] = toMetric(converted[1].before[elem.name]);
    converted[0].after[elem.name] = toMetric(converted[0].after[elem.name]);
    converted[1].after[elem.name] = toMetric(converted[1].after[elem.name]);
  });
  return converted;
};

const convertFromMetricOutput = (data) => {
  console.log("convertToMetricOutput:", data);
  // Don't do anything if there is no input
  if (!data) return data;

  let converted = { ...data };
  const afterSpeeds = converted[0].after.speed_profile.map(
    (speed) => speed * 0.621371
  );
  converted[0].after.speed_profile = afterSpeeds;
  converted[1].after.speed_profile = afterSpeeds;
  OUTPUT_ELEMENTS.forEach((elem) => {
    const { fromMetric = (x) => x } = elem;
    converted[0].before[elem.name] = fromMetric(converted[0].before[elem.name]);
    converted[1].before[elem.name] = fromMetric(converted[1].before[elem.name]);
    converted[0].after[elem.name] = fromMetric(converted[0].after[elem.name]);
    converted[1].after[elem.name] = fromMetric(converted[1].after[elem.name]);
  });
  return converted;
};

const GlobalProvider = (props) => {
  const queryReducer = (state, action) => {
    const { name, value, type, valueType } = action;
    let parsedValue = null;
    if (valueType === "number") parsedValue = parseFloat(value);
    switch (type) {
      case "setSubmitOutputs":
        return {
          ...state,
          submitOutputs: action.selection,
        };
      case "setOutputs":
        return {
          ...state,
          outputs: action.data,
        };
      case "setBeforeScenarioInputs":
        return {
          ...state,
          beforeScenarioInputs: action.selection,
        };
      case "updateBeforeScenarioInputs":
        return {
          ...state,
          beforeScenarioInputs: {
            ...state.beforeScenarioInputs,
            [name]: value,
          },
        };
      case "setAfterScenarioInputs":
        return {
          ...state,
          afterScenarioInputs: action.selection,
        };

      case "updateAfterScenarioInputs":
        return {
          ...state,
          afterScenarioInputs: { ...state.afterScenarioInputs, [name]: value },
        };
      case "setInputs":
        return {
          ...state,
          [name]: parsedValue,
        };
      case "setUseMetricSystem":
        return {
          ...state,
          useMetricSystem: action.value,
        };
      case "toggleMetricSwitch":
        return {
          ...state,
          useMetricSystem: !state.useMetricSystem,
        };
      case "convertToMetric":
        return {
          ...state,
          beforeScenarioInputs: convertToMetric(state.beforeScenarioInputs),
          afterScenarioInputs: convertToMetric(state.afterScenarioInputs),
          outputs: convertToMetricOutput(state.outputs),
          units: { speed: "kmph", volume: "litters", weight: "kgs" },
        };
      case "convertFromMetric":
        return {
          ...state,
          beforeScenarioInputs: convertFromMetric(state.beforeScenarioInputs),
          afterScenarioInputs: convertFromMetric(state.afterScenarioInputs),
          outputs: convertFromMetricOutput(state.outputs),
          units: { speed: "mph", volume: "gallons", weight: "lbs" },
        };
      case "setProjectInfo":
        const { projectInfo } = state;
        return {
          ...state,
          projectInfo: { ...projectInfo, [name]: value },
        };
      case "copyBeforeToAfterInputs":
        return {
          ...state,
          afterScenarioInputs: {
            ...state.beforeScenarioInputs,
            laneAddition: state.afterScenarioInputs.laneAddition,
            treatmentType: state.afterScenarioInputs.treatmentType,
          },
        };
      case "clearInputs":
        return EMPTY_INPUTS;
      case "clearOutputs":
        let newState = { ...state };
        delete newState.outputs;
        return newState;
      default:
        console.log(action);
        throw new Error();
    }
  };
  const [state, dispatch] = useReducer(queryReducer, INITIAL_INPUTS);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalUpdaterContext.Provider value={dispatch}>
        {props.children}
      </GlobalUpdaterContext.Provider>
    </GlobalStateContext.Provider>
  );
};

const useGlobalState = () => {
  const globalState = useContext(GlobalStateContext);
  if (typeof globalState === "undefined") {
    throw new Error("useGlobalState must be used within a GlobalProvider");
  }
  return globalState;
};

const useGlobalUpdater = () => {
  const dispatch = useContext(GlobalUpdaterContext);
  if (typeof dispatch === "undefined") {
    throw new Error("useGlobalUpdater must be used within a GlobalProvider");
  }
  //const increment = useCallback(() => setCount((c) => c + 1), [setCount])
  const updater = useCallback((props) => dispatch(props), [dispatch]);
  return updater;
};

const useTruckInputs = () => {
  const globalState = useContext(GlobalStateContext);
  if (typeof globalState === "undefined") {
    throw new Error("useTruckInputs must be used within a GlobalProvider");
  }
  const inputs = useMemo(() => {
    const after = globalState.afterScenarioInputs;
    const before = globalState.beforeScenarioInputs;
    console.log(["useTruckInputs-before", before]);
    let parsedBefore = parseInputs(before);
    let parsedAfter = parseInputs(after);
    if (globalState.useMetricSystem) {
      parsedBefore = convertFromMetric(parsedBefore);
      parsedAfter = convertFromMetric(parsedAfter);
    }

    let inputs = { ...InputDataForTest };

    inputs.before.Facility.facility_type = parsedBefore.facilityType;
    inputs.before.Facility.aadt = parsedBefore.aadt;
    inputs.before.Facility.lane_length = parsedBefore.length;
    inputs.before.Facility.number_of_lanes = parsedBefore.lanes;
    inputs.before.Facility.lane_addition = parsedBefore.laneAddition;
    inputs.before.Facility.treatment_types = parsedBefore.treatmentTypes;
    inputs.before.Facility.reference_speed = parsedBefore.refSpeed;
    inputs.before.Car.percent = parsedBefore.dailyTruckPercent / 100;
    inputs.before.Car.type = "Truck";
    inputs.before.Car.value_of_time = globalState["value-of-time-truck"];
    inputs.before.Car.fuel_consumption = globalState.useMetricSystem
      ? globalState["fuel-consumption-truck"] / 8.3454
      : globalState["fuel-consumption-truck"];
    inputs.before.Other.climate_cluster = parsedBefore.climateCluster;
    inputs.before.Other.congestion_level = parsedBefore.congestionLevel;
    inputs.before.Other.day_type = parsedBefore.dayType;
    inputs.before.Other.peak_direction = parsedBefore.peakingDirection;
    inputs.before.Other.speed_profile = parsedBefore.speeds;
    inputs.after.Other = inputs.before.Other;
    inputs.after.Car = inputs.before.Car;
    inputs.after.Facility.facility_type = parsedAfter.facilityType;
    inputs.after.Facility.aadt = parsedAfter.aadt;
    inputs.after.Facility.lane_length = parsedAfter.length;
    inputs.after.Facility.number_of_lanes =
      parsedBefore.lanes + parsedAfter.laneAddition;
    inputs.after.Facility.lane_addition = parsedAfter.laneAddition;
    inputs.after.Facility.treatment_types = parsedAfter.treatmentTypes;
    inputs.after.Facility.reference_speed = parsedAfter.refSpeed;
    return inputs;
  }, [globalState]);
  return inputs;
};

const usePassengerInputs = () => {
  const globalState = useContext(GlobalStateContext);
  if (typeof globalState === "undefined") {
    throw new Error("usePassengerInputs must be used within a GlobalProvider");
  }
  const inputs = useMemo(() => {
    const after = globalState.afterScenarioInputs;
    const before = globalState.beforeScenarioInputs;
    let parsedBefore = parseInputs(before);
    let parsedAfter = parseInputs(after);
    if (globalState.useMetricSystem) {
      parsedBefore = convertFromMetric(parsedBefore);
      parsedAfter = convertFromMetric(parsedAfter);
    }
    let inputs = { ...InputDataForTestCar };

    inputs.before.Facility.facility_type = parsedBefore.facilityType;
    inputs.before.Facility.aadt = parsedBefore.aadt;
    inputs.before.Facility.lane_length = parsedBefore.length;
    inputs.before.Facility.number_of_lanes = parsedBefore.lanes;
    inputs.before.Facility.lane_addition = parsedBefore.laneAddition;
    inputs.before.Facility.treatment_types = parsedBefore.treatmentTypes;
    inputs.before.Facility.reference_speed = parsedBefore.refSpeed;
    inputs.before.Car.percent = 1 - parsedBefore.dailyTruckPercent / 100;
    inputs.before.Car.type = "Passenger Vehicle";
    inputs.before.Car.value_of_time = globalState["value-of-time-passenger"];
    inputs.before.Car.fuel_consumption = globalState.useMetricSystem
      ? globalState["fuel-consumption-passenger"] / 8.3454
      : globalState["fuel-consumption-passenger"];
    inputs.before.Other.climate_cluster = parsedBefore.climateCluster;
    inputs.before.Other.congestion_level = parsedBefore.congestionLevel;
    inputs.before.Other.day_type = parsedBefore.dayType;
    inputs.before.Other.peak_direction = parsedBefore.peakingDirection;
    inputs.before.Other.speed_profile = parsedBefore.speeds;
    inputs.after.Other = inputs.before.Other;
    inputs.after.Car = inputs.before.Car;
    inputs.after.Facility.facility_type = parsedAfter.facilityType;
    inputs.after.Facility.aadt = parsedAfter.aadt;
    inputs.after.Facility.lane_length = parsedAfter.length;
    inputs.after.Facility.number_of_lanes =
      parsedBefore.lanes + parsedAfter.laneAddition;
    inputs.after.Facility.lane_addition = parsedAfter.laneAddition;
    inputs.after.Facility.treatment_types = parsedAfter.treatmentTypes;
    inputs.after.Facility.reference_speed = parsedAfter.refSpeed;
    return inputs;
  }, [globalState]);
  return inputs;
};

const copyBeforeToAfterInputs = (dispatch) => {
  dispatch({ type: "copyBeforeToAfterInputs" });
};
export {
  GlobalProvider,
  useGlobalState,
  useGlobalUpdater,
  useTruckInputs,
  usePassengerInputs,
  copyBeforeToAfterInputs,
};
