import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import SendIcon from "@mui/icons-material/Send";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Brush,
} from "recharts";
// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle";
import WidgetEdit from "../../components/WidgetEdit";
import { Typography } from "../../components/Wrappers";
import AfterEditDialog from "./components/AfterEditDialog/AfterEditDialog";
import BeforeEditDialog from "./components/BeforeEditDialog/BeforeEditDialog";
import OtherOptionsEditDialog from "./components/OtherOptionsEditDialog/OtherOptionsEditDialog";
import ProjectInfoDialog from "./components/ProjectInfoEditDialog/ProjectInfoEditDialog";
import ReportDialog from "./components/ReportDialog/ReportDialog";
import SmallStat from "./components/SmallStat/SmallStat";
import SpeedEditDialog from "./components/SpeedEditDialog/SpeedEditDialog";
import SummaryTable from "./components/SummaryTable/SummaryTable";

// constants
import { OUTPUT_ELEMENTS as columns } from "../../constants/UIConstants";
import { SPEEDS } from "../../constants/DefaultConstants";
import mock from "./mock";

// contexts
import {
  useGlobalState,
  useGlobalUpdater,
  usePassengerInputs,
  useTruckInputs,
} from "../../context/GlobalContext";

// hooks
import useFetch from "../../hooks/useFetch";

const getMetricURL = (id = null, from = null, to = null, year = null) => {
  if (id && from && to && year === null) return null;
  return `https://tti-txcat-api-ghactions.azurewebsites.net/api/metrics/aggByRoadSection?year=${year}&route_id=${id}&min_dfo=${from}&max_dfo=${to}`;
};

const getSpeedURL = (id = null, from = null, to = null, year = null) => {
  if (id && from && to && year === null) return null;
  return `https://tti-txcat-api-ghactions.azurewebsites.net/api/speed/aggByRoadSection?year=${year}&route_id=${id}&min_dfo=${from}&max_dfo=${to}`;
};

const groupBy = (dataArray = [], f = () => []) => {
  const groups = {};
  dataArray.forEach((item) => {
    const group = f(item).join("-");
    groups[group] = groups[group] || [];
    groups[group].push(item);
  });
  // return Object.keys(groups).map(function (group) {
  //   return groups[group];
  // });
  return groups;
};

export default function Dashboard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const updater = useGlobalUpdater();
  const state = useGlobalState();
  const [searchParams] = useSearchParams();
  const [chartSelection, setChartSelection] = React.useState("delay");
  const [speedPresets, setSpeedPresets] = React.useState({});

  const info = state.projectInfo;
  const before = state.beforeScenarioInputs;
  const after = state.afterScenarioInputs;
  const outputs = state.outputs;
  const units = state.units;

  const metricFetcher = useFetch(
    "get",
    getMetricURL(
      searchParams.get("id"),
      searchParams.get("from"),
      searchParams.get("to"),
      searchParams.get("year")
    )
  );
  const speedFetcher = useFetch(
    "get",
    getSpeedURL(
      searchParams.get("id"),
      searchParams.get("from"),
      searchParams.get("to"),
      searchParams.get("year")
    )
  );
  useEffect(() => {
    // Preload the data for a given segment on RHINO network
    if (!metricFetcher.serverResponse || !speedFetcher.serverResponse) {
      metricFetcher.setRequest({});
      speedFetcher.setRequest({});
    }
  }, []);

  useEffect(() => {
    let metrics = metricFetcher.serverResponse;
    let speeds = speedFetcher.serverResponse;
    if (metricFetcher.serverError !== null) metrics = null;
    if (speedFetcher.serverError !== null) speeds = null;
    if (metrics !== null) {
      const lanes = (metrics.lanemiles / metrics.len_sec).toFixed(0);
      const length = metrics.len_sec.toFixed(2);
      const aadt = metrics.adt_cur.toFixed(0);
      const pctTrk = (metrics.tottrk / metrics.adt_cur).toFixed(2) * 100;
      const refSpeed = metrics.allrefspee.toFixed(2);
      updater({ type: "clearInputs" });
      updater({
        type: "updateBeforeScenarioInputs",
        name: "aadt",
        value: aadt,
      });
      updater({
        type: "updateBeforeScenarioInputs",
        name: "lanes",
        value: lanes,
      });
      updater({
        type: "updateBeforeScenarioInputs",
        name: "length",
        value: length,
      });
      updater({
        type: "updateBeforeScenarioInputs",
        name: "dailyTruckPercent",
        value: pctTrk,
      });
      updater({
        type: "updateBeforeScenarioInputs",
        name: "refSpeed",
        value: refSpeed,
      });
      updater({
        type: "updateAfterScenarioInputs",
        name: "aadt",
        value: aadt,
      });

      updater({
        type: "updateAfterScenarioInputs",
        name: "lanes",
        value: lanes,
      });

      updater({
        type: "updateAfterScenarioInputs",
        name: "length",
        value: length,
      });
      updater({
        type: "updateAfterScenarioInputs",
        name: "dailyTruckPercent",
        value: pctTrk,
      });
      updater({
        type: "updateAfterScenarioInputs",
        name: "refSpeed",
        value: refSpeed,
      });
    }
    if (speeds !== null) {
      const grouped = groupBy(speeds, (item) => [
        item.direction,
        item.mpodata,
        item.vprofile,
      ]);
      console.log("grouped speeds", grouped);
      setSpeedPresets(grouped);
    }
  }, [
    metricFetcher.serverResponse,
    speedFetcher.serverResponse,
    metricFetcher.serverError,
    speedFetcher.serverError,
  ]);

  const MetricSwitch = ({ checked }) => {
    const handleChange = (e) => {
      console.log("Metric Switch is clicked.", e.target);
      const checkStatus = e.target.checked;
      updater({ type: "toggleMetricSwitch" });
      if (checkStatus) {
        updater({ type: "convertToMetric" });
      }
      if (!checkStatus) updater({ type: "convertFromMetric" });
    };

    return (
      <FormControlLabel
        control={<Switch checked={checked} onChange={handleChange} />}
        label="Metric System"
      />
    );
  };

  const CalculateButton = () => {
    const truckInputs = useTruckInputs();
    const passengerInputs = usePassengerInputs();
    const res = useFetch("post", "https://tti-fixit-api.azurewebsites.net");
    const handleCalculate = (e) => {
      console.log("truckInputs:", truckInputs);
      console.log("passengerInputs:", passengerInputs);
      const body = [
        JSON.stringify(truckInputs),
        JSON.stringify(passengerInputs),
      ];
      const type = "batch";
      res.setRequest({ body, type });
    };

    useEffect(() => {
      console.log("res.serverResponse", res.serverResponse);
      if (res.serverResponse)
        updater({ type: "setOutputs", data: res.serverResponse });
      if (res.serverError) alert(res.serverError);
    }, [res.serverResponse, res.serverError]);

    return (
      <Button
        variant="contained"
        size="medium"
        color="primary"
        onClick={handleCalculate}
      >
        <SendIcon></SendIcon> {res.isLoading ? "Loading..." : "Calculate"}
      </Button>
    );
  };
  /**
   * Customized tools for speed box
   * It provides preloaded speeds for the user to select
   * @returns
   */
  const CustomSpeedTools = () => {
    console.log("speedPresets:", speedPresets);
    const updateSpeed = (data) => {
      updater({
        type: "clearOutputs",
      });
      updater({
        type: "updateBeforeScenarioInputs",
        name: "speeds",

        value: SPEEDS.map(({ time }, index) => {
          return {
            time,
            speed: parseFloat(data[index].weighted_avg_allspd.toFixed(1)),
          };
        }),
      });
    };
    const updateOther = (data = []) => {
      const dayType = data[0]?.day_type || null;
      const facilityType = data[0]?.functional_class || null;
      const peakingDirection = data[0]?.peaking_direction || null;
      const congestionLevel = data[0]?.congestion_level || null;
      updater({
        type: "updateBeforeScenarioInputs",
        name: "dayType",
        value: dayType,
      });
      updater({
        type: "updateBeforeScenarioInputs",
        name: "facilityType",
        value: facilityType,
      });
      updater({
        type: "updateBeforeScenarioInputs",
        name: "peakingDirection",
        value: peakingDirection,
      });
      updater({
        type: "updateBeforeScenarioInputs",
        name: "congestionLevel",
        value: congestionLevel,
      });
      updater({
        type: "updateAfterScenarioInputs",
        name: "facilityType",
        value: facilityType,
      });
    };
    return (
      <>
        {Object.entries(speedPresets).map((entry) => (
          <Button
            onClick={() => {
              updateSpeed(entry[1]);
              updateOther(entry[1]);
            }}
          >
            {entry[0]}
          </Button>
        ))}
      </>
    );
  };
  /**
   * Create the rows for the output datatable.
   * Example:
   * [{
   *    type:"Truck",
   *    scenario: "before",
   *    delay: ####,
   *    waste_of_fuel: ####,
   *    co2: #####,
   *    co2_of_delay: #####,
   *    ...rest
   * }]
   */
  const createTableData = React.useCallback((data) => {
    let rows = [];
    ["Truck", "Passenger Vehicle"].forEach((type, id) => {
      ["before", "after"].forEach((scenario) => {
        let newRow = { type, scenario };
        if (data) {
          let rowData = data[id][scenario];
          columns.forEach((column) => {
            newRow[column.name] = rowData[column.name];
          });
        }

        rows.push(newRow);
      });
    });
    return rows;
  });

  const createBarChartData = (data) => {
    if (!data)
      return [
        {
          name: "before",
          passenger: null,
          truck: null,
        },
        {
          name: "after",
          passenger: null,
          truck: null,
        },
      ];
    const truck = data[0];
    const passenger = data[1];
    const getValue = (vehicleData, scenario, selection) => {
      const value = vehicleData[scenario][selection];
      return value ? value.toFixed(0) : null;
    };
    const chartData = [
      {
        name: "before",
        passenger: getValue(passenger, "before", chartSelection),
        truck: getValue(truck, "before", chartSelection),
      },
      {
        name: "after",
        passenger: getValue(passenger, "after", chartSelection),
        truck: getValue(truck, "after", chartSelection),
      },
    ];
    return chartData;
  };
  const createSpeedData = (outputs) => {
    const afterSpeeds = outputs
      ? outputs[0].after.speed_profile
      : before.speeds.map((item) => {
          return null;
        });
    console.log("Speed chart data-afterSpeeds:", afterSpeeds);
    const speeds = before.speeds.map(({ time, speed }, index) => ({
      time,
      beforeSpeed: speed,
      afterSpeed: afterSpeeds[index],
      beforeRef: before.refSpeed,
      afterRef: after.refSpeed,
    }));
    console.log("Speed chart data:", speeds);
    return speeds;
  };
  const rows = createTableData(outputs);

  //Function to handle the change of chart selection dropdown
  const handleChartSelectionChange = (e) => {
    e.preventDefault();
    setChartSelection(e.target.value);
  };
  return (
    <>
      <PageTitle
        title="Dashboard"
        button={[
          <MetricSwitch checked={state.useMetricSystem}></MetricSwitch>,
          <CalculateButton></CalculateButton>,
        ]}
      />
      {/* <PageTitle title="Dashboard" button={[<MetricSwitch checked={state.useMetricSystem}></MetricSwitch>, <CalculateButton></CalculateButton>, <ReportDialog />]} /> */}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={1}>
            {/* Project Info*/}
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <WidgetEdit
                title="Project Info"
                upperTitle
                bodyClass={classes.fullHeightBody}
                className={classes.card}
                dialogComponent={
                  <ProjectInfoDialog
                    data={{ ...state.projectInfo }}
                    title="Project Info"
                  ></ProjectInfoDialog>
                }
              >
                <div className={classes.visitsNumberContainer}>
                  <Grid container alignItems={"center"} spacing={1}>
                    <Grid item xs={12}>
                      <Typography size="medium" weight="medium" noWrap>
                        Base Analysis Year: {info.baseAnalysisYear}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography size="medium" weight="medium" noWrap>
                        Facility Name: {info.facilityName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography size="medium" weight="medium" noWrap>
                        Facility Location: {info.facilityLocation}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography size="medium" weight="medium" noWrap>
                        Project Description: {info.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </WidgetEdit>
            </Grid>
            {/* Before Inputs*/}
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <WidgetEdit
                title="Before Scenario"
                upperTitle
                className={classes.card}
                // bodyClass={classes.fullHeightBody}
                dialogComponent={
                  <BeforeEditDialog
                    data={{ ...before, useMetricSystem: state.useMetricSystem }}
                    title="Before Scenario"
                  ></BeforeEditDialog>
                }
              >
                <Grid container alignItems={"center"} spacing={1}>
                  <Grid item xs={6}>
                    <Typography size="medium" weight="medium" noWrap>
                      AADT: {before.aadt}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography size="medium" weight="medium" noWrap>
                      Lanes: {before.lanes}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography size="medium" weight="medium" noWrap>
                      Facility Type: {before.facilityType}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography size="medium" weight="medium" noWrap>
                      Freeflow Speed:{" "}
                      {before.refSpeed && (
                        <>
                          {parseFloat(before.refSpeed).toFixed(2)}{" "}
                          {state.useMetricSystem ? " kmph" : " mph"}
                        </>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography size="medium" weight="medium" noWrap>
                      Length: {parseFloat(before.length).toFixed(2)}{" "}
                      {state.useMetricSystem ? "km" : "mile"}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography size="medium" weight="medium" noWrap>
                      Daily Truck Percentage: {before.dailyTruckPercent}
                    </Typography>
                  </Grid>
                </Grid>
              </WidgetEdit>
            </Grid>
            {/* Optional Inputs*/}
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <WidgetEdit
                title="Other Options"
                upperTitle
                className={classes.card}
                // bodyClass={classes.fullHeightBody}
                dialogComponent={
                  <OtherOptionsEditDialog
                    data={before}
                    title="Other Options"
                  ></OtherOptionsEditDialog>
                }
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography size="medium" weight="medium" noWrap>
                      Day Type: {before.dayType}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography size="medium" weight="medium" noWrap>
                      Peaking Direction: {before.peakingDirection}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography size="medium" weight="medium" noWrap>
                      Congestion Level: {before.congestionLevel}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography size="medium" weight="medium" noWrap>
                      Climate Cluster: {before.climateCluster}
                    </Typography>
                  </Grid>
                </Grid>
              </WidgetEdit>
            </Grid>

            {/* After Inputs*/}
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <WidgetEdit
                title="After Scenario"
                upperTitle
                className={classes.card}
                dialogComponent={
                  <AfterEditDialog
                    data={{ ...after, useMetricSystem: state.useMetricSystem }}
                    title="After Scenario"
                  ></AfterEditDialog>
                }
              >
                <Grid container alignItems={"center"} spacing={1}>
                  <Grid item xs={6}>
                    <Typography size="medium" weight="medium" noWrap>
                      AADT: {after.aadt}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography size="medium" weight="medium" noWrap>
                      Lanes:{" "}
                      {after.treatmentType === "Add new lanes"
                        ? parseFloat(after.lanes) +
                          parseFloat(after.laneAddition)
                        : parseFloat(after.lanes)}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography size="medium" weight="medium" noWrap>
                      Facility Type: {after.facilityType}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography size="medium" weight="medium" noWrap>
                      Freeflow Speed:
                      {after.refSpeed && (
                        <>
                          {parseFloat(after.refSpeed).toFixed(2)}{" "}
                          {state.useMetricSystem ? " kmph" : " mph"}
                        </>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography size="medium" weight="medium" noWrap>
                      Length: {parseFloat(after.length).toFixed(2)}{" "}
                      {state.useMetricSystem ? "km" : "mile"}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography size="medium" weight="medium" noWrap>
                      Daily Truck Percentage: {after.dailyTruckPercent}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography size="medium" weight="medium" noWrap>
                      Treatment Type: {after.treatmentType}
                    </Typography>
                  </Grid>
                </Grid>
              </WidgetEdit>
            </Grid>

            {/* Speed Profile*/}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <WidgetEdit
                bodyClass={classes.fullHeightBody}
                className={classes.card}
                // title={<Typography variant="h5" >Speed Profile(<span style={{ color: "blue" }}>Before</span> and <span style={{ color: "red" }}>After</span>)</Typography>}
                title="Speed Profile"
                toolBar={<CustomSpeedTools></CustomSpeedTools>}
                dialogComponent={
                  <SpeedEditDialog
                    title="Speed Profile(Before and After)"
                    onSave={(data) =>
                      updater({
                        type: "updateBeforeScenarioInputs",
                        name: "speeds",
                        value: data,
                      })
                    }
                    data={before.speeds}
                  ></SpeedEditDialog>
                }
              >
                <ResponsiveContainer width="100%" minWidth={500} height={325}>
                  <ComposedChart
                    margin={{ top: 15, bottom: 15 }}
                    data={createSpeedData(outputs)}
                  >
                    <YAxis
                      domain={["auto", "auto"]}
                      interval={0}
                      tick={{ fontSize: "0.8em" }}
                      // tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                      // stroke={theme.palette.text.hint + "100"}
                      tickLine={true}
                    />
                    <XAxis
                      dataKey="time"
                      textAnchor="end"
                      tick={{ angle: -45, fontSize: "0.7em" }}
                      tickLine={true}
                      height={60}
                    />
                    <Legend verticalAlign="top" />

                    <Line
                      type="natural"
                      name="Before"
                      dataKey="beforeSpeed"
                      stroke={theme.palette.primary.main}
                      strokeWidth={2}
                      dot={false}
                      activeDot={false}
                    />
                    <Line
                      type="natural"
                      dataKey="afterSpeed"
                      stroke={theme.palette.error.light}
                      strokeWidth={2}
                      dot={false}
                      name="After"
                      activeDot={false}
                    />
                    <Line
                      type="natural"
                      name="Posted/Freeflow speed-Before"
                      dataKey="beforeRef"
                      stroke={theme.palette.warning.main}
                      strokeWidth={2}
                      dot={false}
                      activeDot={false}
                    />

                    <Line
                      type="natural"
                      name="Posted/Freeflow speed-After"
                      dataKey="afterRef"
                      stroke={theme.palette.success.dark}
                      strokeWidth={2}
                      dot={false}
                      activeDot={false}
                    />

                    <Brush
                      dataKey="time"
                      height={25}
                      stroke={theme.palette.primary.main}
                      // travellerWidth={12}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </WidgetEdit>
            </Grid>
          </Grid>
        </Grid>

        {/* Output*/}
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid container spacing={1}>
            {mock.smallStat.map((stat) => (
              <Grid item md={4} sm={6} xs={12} key={stat.product}>
                <SmallStat {...stat} {...{ outputs, type: chartSelection }} />
              </Grid>
            ))}
            <Grid item xs={12}>
              <WidgetEdit
                bodyClass={classes.fullHeightBody}
                className={classes.card}
                title="Bar Chart"
                header={
                  <div
                    className={classes.mainChartHeader}
                    style={{ minWidth: "100%" }}
                  >
                    {/* <Typography variant="h5">Bar Chart</Typography> */}
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-select-small">
                        Chart Selection
                      </InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        label="Chart Selection"
                        onChange={handleChartSelectionChange}
                        defaultValue={columns[0].name}
                      >
                        {columns.map((col) => (
                          <MenuItem value={col.name}>{col.label}</MenuItem>
                        ))}
                        {/* <MenuItem value={"delay"}>Delay</MenuItem>
                        <MenuItem value={"wasted_fuel"}>Wasted Fuel</MenuItem>
                        <MenuItem value={"co2"}>CO2</MenuItem> */}
                      </Select>
                    </FormControl>
                  </div>
                }
              >
                <ResponsiveContainer width="100%" minWidth={500} height={175}>
                  <BarChart
                    layout="vertical"
                    data={createBarChartData(outputs)}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      type="number"
                      tickFormatter={(tick) => {
                        return tick.toLocaleString();
                      }}
                    />
                    <YAxis type="category" dataKey="name" />
                    <Tooltip formatter={(value) => value.toLocaleString()} />
                    <Legend />
                    <Bar dataKey="passenger" stackId={"stack"} fill="#8884d8" />
                    <Bar dataKey="truck" stackId={"stack"} fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </WidgetEdit>
            </Grid>

            {/* Output Table */}
            <Grid item xs={12}>
              <WidgetEdit
                title="Summary Table"
                //upperTitle
                header={
                  <div
                    className={classes.mainChartHeader}
                    style={{ minWidth: "100%" }}
                  >
                    <Typography variant="h5">Summary Table</Typography>
                  </div>
                }
                bodyClass={classes.tableWidget}
              >
                <Grid container spacing={2}>
                  {/* <Grid item xs={12}><Table data={mock.table} />
                  </Grid> */}
                  <Grid item xs={12}>
                    <SummaryTable {...{ rows, units }}></SummaryTable>
                  </Grid>
                </Grid>
              </WidgetEdit>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
