import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "@mui/material";
import content from "./content.json";
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ImageCard from "./ImageCard";
import DownloadFiles from "./DownloadFiles";
import { saveFile } from "./SaveFile";
import { Link } from "react-router-dom";
import {useNavigate} from "react-router-dom";

const handleDownloadClick = (fileName, type) => {
  saveFile(fileName, type);
};

/* Constants to be used in Home Page */
const HomePageConstants = {
  PAGE_TITLE: "Future Improvement Examination Technique (FIXiT)",
  OVERVIEW: "Overview",
  FEATURES: "Features",
  TRAINING: "Training",
  USER_GUIDES: "User Guides",
  DWN_USER_GUIDES: "Download User Guides below :",
  CASE_STUDIES: "Case Studies",
  DWN_CASE_STUDIES: "Download Case Studies below :",
  USER_GROUP: "User Group",
  RESOURCES: "Resources",
  MAINTENANCE:
    "This section's content is yet to be uploaded. Thank you for your patience.",
};

/* Displays tab content */
function CustomTabPanel(props) {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

/* Displays tab header */
function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function HomePage() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, value) => {
    setValue(value);
  };
  const navigate = useNavigate();

  return (
    <>
      {/* Displays page title */}
      <PageTitle title={HomePageConstants.PAGE_TITLE} />

      {/* Displays image banner */}
      <ImageCard />

      <Box sx={{ width: "100%" }}>
        {/* Displays tab headers */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="home page tabs"
          >
            <Tab label="Overview" {...tabProps(0)} sx={{ align: "left" }} />
            <Tab label="Features" {...tabProps(1)} sx={{ align: "left" }} />
            <Tab label="Training" {...tabProps(2)} sx={{ align: "left" }} />
            <Tab label="Case Studies" {...tabProps(3)} sx={{ align: "left" }} />
            <Tab label="User Group" {...tabProps(4)} sx={{ align: "left" }} />
            <Tab label="Resources" {...tabProps(5)} sx={{ align: "left" }} />
          </Tabs>
        </Box>

        {/* Displays overview tab content */}
        <CustomTabPanel value={value} index={0}>
          <Typography variant="body1">
            {content.overview_text.split("\n").map((line) => (
              <>
                <br />
                {line}
                <br />
              </>
            ))}
            <br />
            <Link to="/dashboard" onClick={()=>navigate("/dashboard")}>FIXiT Facility</Link>
            
            {content.overview_text2}
            <Link
              onClick={(e) =>
                handleDownloadClick(
                  "SUMA Task 3_FIXiT 4.0 Simple.xlsx",
                  "fixit-regional"
                )
              }
            >
              here
            </Link>
            {" "} and the user’s guide{" "}
            <Link
              onClick={(e) =>
                handleDownloadClick(
                  "SUMA Task 3_FIXIT 4.0 Simple User Guide.pdf",
                  "fixit-regional"
                )
              }
            >
              here.
            </Link>
          </Typography>
        </CustomTabPanel>

        {/* Displays features tab content */}
        <CustomTabPanel value={value} index={1}>
          <ul sx={{ margin: 0.01 }}>
            {content.features.map((feature) => (
              <li>
                <Typography variant="body1">
                  <strong>{feature.key}</strong>: {feature.value}
                </Typography>
              </li>
            ))}
          </ul>
        </CustomTabPanel>

        {/* Displays training tab content */}
        <CustomTabPanel value={value} index={2}>
          <>
            <Box>
              <br />
              <Typography variant="body1">
                <strong>{HomePageConstants.DWN_USER_GUIDES}</strong>
              </Typography>
              <ul>
                {content.user_guides.map((user_guide) => (
                  <>
                    <li>
                      <DownloadFiles
                        fileName={user_guide.key}
                        displayName={user_guide.value}
                        type="users-guide"
                      />
                    </li>
                  </>
                ))}
              </ul>
            </Box>
          </>
        </CustomTabPanel>

        {/* Displays case study tab content */}
        <CustomTabPanel value={value} index={3}>
          <>
            <Box>
              <br />
              <Typography variant="body1">
                <strong>{HomePageConstants.DWN_CASE_STUDIES}</strong>
              </Typography>
              <ul>
                {content.case_studies.map((case_study) => (
                  <li>
                    {case_study.map((c) => (
                      <>
                        <DownloadFiles
                          fileName={c.key}
                          displayName={c.value}
                          type="case-studies"
                        />
                      </>
                    ))}
                  </li>
                ))}
              </ul>
            </Box>
          </>
        </CustomTabPanel>

        {/* Displays user group tab content */}
        <CustomTabPanel value={value} index={4}>
          <>
            <br />
            {HomePageConstants.MAINTENANCE}
          </>
        </CustomTabPanel>

        {/* Displays resouces tab content */}
        <CustomTabPanel value={value} index={5}>
          <>
            <br />
            {HomePageConstants.MAINTENANCE}
          </>
        </CustomTabPanel>
      </Box>
    </>
  );
}
