export const InputDataForTest = {
  before: {
    Car: {
      type: "Truck",
      percent: 0.15,
      fuel_consumption: 0.044557,
      value_of_time: 54.94,
    },
    Facility: {
      facility_type: "Freeway",
      aadt: 21417,
      number_of_lanes: 3,
      lane_length: 3.57,
      reference_speed: 60,
      functional_class: "Freeway",
    },
    Other: {
      day_type: "Weekday",
      functional_class_co2: "Freeway",
      peak_direction: "AM Peak",
      climate_cluster: 1,
      congestion_level: "Low",
      speed_profile: [
        69, 68, 68, 68, 64, 66, 66, 70, 68, 69, 67, 66, 67, 67, 69, 70, 69, 69,
        69, 70, 71, 70, 70, 71, 70, 68, 69, 72, 68, 68, 68, 68, 68, 68, 68, 67,
        69, 67, 66, 68, 67, 67, 68, 67, 65, 66, 66, 66, 66, 67, 67, 68, 67, 67,
        66, 66, 67, 65, 65, 64, 63, 63, 62, 55, 45, 33, 35, 35, 31, 37, 32, 31,
        29, 32, 31, 44, 66, 64, 66, 66, 66, 67, 66, 67, 66, 67, 69, 70, 68, 67,
        69, 67, 69, 67, 66, 70,
      ],
    },
  },
  after: {
    Car: {
      type: "Truck",
      percent: 0.15,
      fuel_consumption: 0.044557,
      value_of_time: 54.94,
    },
    Facility: {
      facility_type: "Freeway",
      aadt: 21417,
      number_of_lanes: 4,
      lane_length: 3.57,
      reference_speed: 60,
      functional_class: "Freeway",
    },
    Other: {
      day_type: "Weekday",
      functional_class_co2: "Freeway",
      peak_direction: "AM Peak",
      climate_cluster: 1,
      congestion_level: "Low",
      speed_profile: [
        69, 68, 68, 68, 64, 66, 66, 70, 68, 69, 67, 66, 67, 67, 69, 70, 69, 69,
        69, 70, 71, 70, 70, 71, 70, 68, 69, 72, 68, 68, 68, 68, 68, 68, 68, 67,
        69, 67, 66, 68, 67, 67, 68, 67, 65, 66, 66, 66, 66, 67, 67, 68, 67, 67,
        66, 66, 67, 65, 65, 64, 63, 63, 62, 55, 45, 33, 35, 35, 31, 37, 32, 31,
        29, 32, 31, 44, 66, 64, 66, 66, 66, 67, 66, 67, 66, 67, 69, 70, 68, 67,
        69, 67, 69, 67, 66, 70,
      ],
    },
  },
};
