import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import congestionImg from "../../images/traffic-congestion.jpg";

export default function ImageCard(props) {
  return (
    <Card>
      <div style={{ width: "100%", height: "320px" }}>
        <CardMedia
          component="img"
          image={congestionImg}
          title="Fixit Banner"
          alt="Fixit Image Card"
        />
      </div>
    </Card>
  );
}
